<template>
  <div>
    <v-row align="center">
      <v-col cols="6">
        <v-card>
          <v-container fluid class="pa-10">
            <h4>Filter Daftar Operator SIMPEL-ASN</h4>
            <v-text-field
              v-model="tahun"
              :rules="rules"
              label="Tahun"
              type="number"
            ></v-text-field>
            <div class="text-center">
              <v-btn
                color="secondary"
                :disabled="!valid"
                @click="getSimpegOperator(tahun)"
              >
                <v-icon left dark>mdi-filter-cog-outline</v-icon>
                Filter
              </v-btn>
            </div>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-form ref="formSetting" v-model="validSetting" lazy-validation>
            <v-container fluid class="pa-10">
              <h4>Batas Pendaftaran</h4>
              <small class="text-caption">Tanggal</small>
              <datetime
                format="YYYY-MM-DD H:i:s"
                width="100%"
                v-model="tanggal_tutup"
                label="tanggal"
              ></datetime>
              <!-- <v-text-field
                v-model="tanggal_tutup"
                :rules="rules"
                label="Tanggal"
                type="date"
              ></v-text-field> -->
              <div class="text-center">
                <v-btn
                  color="success"
                  :disabled="!validSetting"
                  @click="submitSetting"
                >
                  <v-icon left dark>mdi-content-save</v-icon>
                  Simpan Setting
                </v-btn>
              </div>
            </v-container>
          </v-form>
        </v-card>
      </v-col> </v-row
    ><br />
    <v-data-table
      :headers="headers"
      :items="datas"
      sort-by="calories"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:item.nomor="{ item }">
        {{ datas.indexOf(item) + 1 }}
      </template>
      <template v-slot:top>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="white--text"
            >Daftar Operator SIMPEL-ASN</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn small color="secondary" class="mb-2" @click="makePDF">
            <v-icon small>mdi-printer</v-icon>
            Export PDF
          </v-btn>
          <v-dialog v-model="dialog" max-width="500px">
            <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              color="secondary"
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>mdi-plus</v-icon>
              Tambah
            </v-btn>
          </template> -->
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card-text class="black--text">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.jenis"
                          :rules="rules"
                          label="Jenis KENPA"
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.singkatan"
                          :rules="rules"
                          label="Singkatan"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Batal
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                    :disabled="!valid"
                  >
                    Simpan
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Anda yakin ingin menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Batal</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-text-field v-model="search" label="Cari" class="mx-4"></v-text-field>
      </template>
      <template v-slot:item.actions="{ item }">
        <!-- <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon> -->
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data> Tidak ada data </template>
    </v-data-table>

    <div id="print" hidden>
      <center style="font-family: Arial, Helvetica, sans-serif">
        <b>DAFTAR OPERATOR SIMPEL-ASN</b><br /><br />
        <table
          border="1"
          width="100%"
          style="
            border-collapse: collapse;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 8pt;
            margin-top: 2px;
          "
          align="center"
          cellpadding="2px"
        >
          <tbody>
            <tr>
              <th>No</th>
              <th>SKPD</th>
              <th style="width: 23%">Nama Lengkap</th>
              <th>Status</th>
              <th>NIP</th>
              <th>Nomor HP</th>
              <th>Email</th>
            </tr>
            <tr v-for="dt in datas" :key="dt.id">
              <td align="center">{{ datas.indexOf(dt) + 1 }}</td>
              <td>
                {{ dt.skpd.unorname }}
              </td>
              <td>
                {{ dt.nama.toUpperCase() }}
              </td>
              <td>{{ dt.status_pns_ket }}</td>
              <td>{{ dt.nip }}</td>
              <td>{{ dt.no_hp }}</td>
              <td>{{ dt.email }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td
                colspan="7"
                style="
                  border-right: 1px solid #ffffff;
                  border-left: 1px solid #ffffff;
                  border-bottom: 1px solid #ffffff;
                "
              >
                <small style="color: grey"
                  ><i
                    >*dicetak dari {{ urlweb }} pada
                    {{
                      new Date() | moment("dddd, DD MMMM YYYY, H:mm:ss")
                    }}
                    WITA</i
                  ></small
                >
              </td>
            </tr>
          </tfoot>
        </table>
      </center>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import datetime from "vuejs-datetimepicker";
export default {
  components: { datetime },
  data: () => ({
    urlweb: process.env.VUE_APP_FRONTEND_URL,
    valid: true,
    validSetting: true,
    dialog: false,
    dialogDelete: false,
    search: "",
    menu2: false,
    headers: [
      { text: "No", value: "nomor" },
      {
        text: "SKPD",
        align: "left",
        value: "skpd.unorname",
      },
      { text: "Nama Lengkap", value: "nama" },
      { text: "Status", value: "status_pns_ket" },
      { text: "NIP", value: "nip" },
      { text: "Nomor HP", value: "no_hp" },
      { text: "Email", value: "email" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    datas: [],
    statusItems: [
      { id: "0", status: "Tutup" },
      { id: "1", status: "Buka" },
    ],
    tahun: new Date().getFullYear(),
    tanggal_tutup: null,
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Edit Data";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
      setAuth: "auth/set", // <=
    }),

    initialize() {
      this.getSimpegOperator(this.tahun);
      this.getSimpegOperatorSetting();
    },

    getSimpegOperator(tahun) {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/simpegoperator/simpeg_operator_join_by_tahun/" + tahun, config)
        .then((response) => {
          console.log(response.data);
          this.datas = response.data;
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    getSimpegOperatorSetting() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/simpegoperator/simpeg_operator_setting", config)
        .then((response) => {
          console.log(response.data[0].tanggal_tutup);
          this.tanggal_tutup = response.data[0].tanggal_tutup;
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //   DELETE DATA
      //   this.datas.splice(this.editedIndex, 1);
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .delete(
          "/simpegoperator/simpeg_operator_delete/" +
            this.editedItem.id_operator,
          config
        )
        .then(() => {
          this.$vloading.hide();
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dihapus!",
          });
          this.close();
          this.getSimpegOperator(this.tahun);
          this.getSimpegOperatorSetting();
          this.closeDelete();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          // EDIT DATA
          // Object.assign(this.datas[this.editedIndex], this.editedItem);
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.data.access_token,
            },
          };
          let formData = new FormData();
          formData.set("jenis", this.editedItem.jenis);
          formData.set("singkatan", this.editedItem.singkatan);
          this.axios
            .post(
              "/kenpa/jenis_kenpa_update/" + this.editedItem.id_jenis,
              formData,
              config
            )
            .then(() => {
              this.$vloading.hide();
              // console.log(response);
              this.setAlert({
                status: true,
                color: "success",
                text: "Data Berhasil Diubah!",
              });
              this.close();
              this.getSimpegOperator();
            })
            .catch((error) => {
              this.$vloading.hide();
              // console.log(error.response.data.messages);
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.messages,
              });
              this.close();
            });
        } else {
          // INSERT DATA
          // this.datas.push(this.editedItem);
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.data.access_token,
            },
          };
          let formData = new FormData();
          formData.set("jenis", this.editedItem.jenis);
          formData.set("singkatan", this.editedItem.singkatan);
          this.axios
            .post("/kenpa/jenis_kenpa_add", formData, config)
            .then(() => {
              this.$vloading.hide();
              // console.log(response);
              this.setAlert({
                status: true,
                color: "success",
                text: "Data Berhasil Ditambahkan!",
              });
              this.close();
              this.getSimpegOperator();
            })
            .catch((error) => {
              this.$vloading.hide();
              // console.log(error.response.data.messages);
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.messages,
              });
              this.close();
            });
        }
        this.close();
      }
    },

    submitSetting() {
      if (this.$refs.formSetting.validate()) {
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.access_token,
          },
        };
        let formData = new FormData();
        formData.set("tanggal_tutup", this.tanggal_tutup);
        this.axios
          .post(
            "/simpegoperator/simpeg_operator_setting_update",
            formData,
            config
          )
          .then(() => {
            this.$vloading.hide();
            // console.log(response);
            this.setAlert({
              status: true,
              color: "success",
              text: "Setting berhasil disimpan!",
            });
            this.close();
            this.getSimpegOperator(this.tahun);
            this.getSimpegOperatorSetting();
          })
          .catch((error) => {
            this.$vloading.hide();
            // console.log(error.response.data.messages);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          });
      }
    },

    makePDF() {
      var prtContent = document.getElementById("print");
      var WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(prtContent.innerHTML);
      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(function () {
        WinPrint.print();
        WinPrint.close();
      }, 500);
    },
  },
};
</script>