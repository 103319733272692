<template>
  <div>
    <v-card class="py-4 mb-4">
      <div align="center">
        Batas waktu Pendaftaran <br />
        <v-chip color="info" label class="text-subtitle-1"
          >{{ tgl_tutup | moment("DD MMMM YYYY, H:mm:ss") }} WITA</v-chip
        >
        <!-- {{ tgl_tutup }} -->
        <br />
        <flip-countdown v-if="tgl_tutup" :deadline="tgl_tutup"></flip-countdown>
        <!-- <br />
        {{ tgl_tutup }}<br />
        {{ dateTimeNow }}<br />
        <span v-if="dateTimeNow <= tgl_tutup"> halo </span> -->
      </div>
    </v-card>
    <v-card v-if="Date.parse(dateTimeNow) <= Date.parse(tgl_tutup)">
      <v-toolbar dark color="primary">
        <v-toolbar-title class="text-subtitle-2 text-md-h6"
          >Formulir Pendaftaran Operator SIMPEL-ASN</v-toolbar-title
        >
      </v-toolbar>

      <v-divider></v-divider>

      <v-container fluid class="pa-10">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-autocomplete
            v-model="unorid"
            :items="unorItems"
            :rules="rules"
            item-value="unorid"
            item-text="unorname"
            label="Unit Kerja"
            dense
            class="text-caption text-md-subtitle-1 text-lg-subtitle-1"
            clearable
          ></v-autocomplete>
          <br />
          <v-select
            v-model="status_pns"
            :items="statuspnsItems"
            :rules="rules"
            item-value="id"
            item-text="status"
            label="Status"
            dense
            class="text-caption text-md-subtitle-1 text-lg-subtitle-1"
          ></v-select
          ><br />
          <v-autocomplete
            v-if="status_pns == 1"
            v-model="newsid"
            :loading="loading"
            :items="newsidItems"
            :search-input.sync="search"
            item-value="newsid"
            item-text="nama"
            label="Cari Pegawai"
            v-on:change="pegawaiChange"
            class="text-caption text-md-subtitle-1 text-lg-subtitle-1"
            clearable
            hide-no-data
          ></v-autocomplete>
          <br v-if="status_pns == 1" />
          <v-text-field
            v-if="status_pns == 1"
            v-model="nip"
            :rules="rules"
            label="NIP"
            dense
            disabled
            class="text-caption text-md-subtitle-1 text-lg-subtitle-1"
          ></v-text-field>
          <br v-if="status_pns == 1" />
          <v-text-field
            v-if="status_pns == 0"
            v-model="nama"
            :rules="rules"
            label="Nama Lengkap"
            dense
            class="text-caption text-md-subtitle-1 text-lg-subtitle-1"
          ></v-text-field>
          <br v-if="status_pns == 0" />
          <v-text-field
            v-model="no_hp"
            :rules="rules"
            label="Nomor HP"
            dense
            class="text-caption text-md-subtitle-1 text-lg-subtitle-1"
          ></v-text-field>
          <br />
          <v-text-field
            v-model="email"
            :rules="rules"
            label="Email"
            dense
            class="text-caption text-md-subtitle-1 text-lg-subtitle-1"
          ></v-text-field>
          <div class="text-xs-center">
            <br />
            <v-btn
              color="primary"
              :disabled="!valid || loading"
              @click="submit"
              :loading="loading"
            >
              Kirim

              <v-icon right dark>mdi-content-save</v-icon>
            </v-btn>
          </div>
        </v-form>
      </v-container>
    </v-card>
    <v-card v-else>
      <v-toolbar dark color="primary">
        <v-toolbar-title class="text-subtitle-2 text-md-h6"
          >Formulir Pendaftaran Operator SIMPEL-ASN</v-toolbar-title
        >
      </v-toolbar>

      <v-divider></v-divider>

      <v-container fluid class="pa-10 text-center">
        <v-chip large color="error" label>
          <v-icon>mdi-cancel</v-icon>&nbsp; Sudah Ditutup
        </v-chip>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FlipCountdown from "vue2-flip-countdown";
export default {
  name: "AddSimpegOperator",
  components: { FlipCountdown },
  data: () => ({
    valid: true,
    loading: false,
    dateTimeNow: null,
    tgl_tutup: null,
    newsid: "",
    id_tipe: "",
    status_pns: "",
    unorid: "",
    nip: "",
    nama: "",
    no_hp: "",
    email: "",
    tipeItems: [
      { id: "1", tipe: "SKPD" },
      { id: "2", tipe: "PUSKESMAS" },
    ],
    statuspnsItems: [
      { id: "1", status: "ASN" },
      { id: "0", status: "Non ASN" },
    ],
    unorItems: [],
    newsidItems: [],
    rules: [(v) => !!v || "Form ini wajib di isi"],
    search: null,
  }),

  created() {
    this.initialize();
  },

  watch: {
    search(val) {
      val && val !== this.newsid && this.querySelections(val);
    },
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
    }),

    initialize() {
      this.getUnorParent();
      this.getSimpegOperatorSetting();
      this.getCurrentDatetime();
    },

    querySelections(v) {
      let config = {
        headers: {},
      };
      this.axios
        .get("/simpeg/r_dip_all_join/" + v, config)
        .then((response) => {
          this.$vloading.hide();
          // console.log(response.data);
          this.newsidItems = response.data;
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    pegawaiChange: function(e) {
      // console.log(e);
      this.getPegawaibyId(e);
    },

    getPegawaibyId(id) {
      this.$vloading.show();
      let config = {
        headers: {},
      };
      this.axios
        .get("/simpeg/r_dip_all_join_newsid/" + id, config)
        .then((response) => {
          this.$vloading.hide();
          // console.log(response.data);
          this.nip = response.data[0].nip2;
          this.nama = response.data[0].nama;
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    getUnorParent() {
      this.$vloading.show();
      let config = {
        headers: {},
      };
      this.axios
        .get("/simpegoperator/unor_parent", config)
        .then((response) => {
          this.$vloading.hide();
          // console.log(response.data);
          this.unorItems = response.data;
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    getSimpegOperatorSetting() {
      this.$vloading.show();
      let config = {
        headers: {
          // Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/simpegoperator/simpeg_operator_setting", config)
        .then((response) => {
          this.tgl_tutup = response.data[0].tanggal_tutup;
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    getCurrentDatetime() {
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var dateTime = date + " " + time;
      this.dateTimeNow = dateTime;
      // console.log(dateTime);
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let formData = new FormData();
        formData.set("unorid", this.unorid);
        formData.set("nama", this.nama);
        formData.set("no_hp", this.no_hp);
        formData.set("email", this.email);
        formData.set("status_pns", this.status_pns);
        if (this.status_pns == "0") {
          formData.set("nip", "");
        } else {
          formData.set("nip", this.nip);
        }
        this.axios
          .post("/simpegoperator/simpeg_operator_add", formData)
          .then(() => {
            this.setAlert({
              status: true,
              color: "success",
              text: "Pendaftaran Berhasil!",
            });
            this.close();
            this.loading = false;
            this.$refs.form.reset();
          })
          .catch((error) => {
            // let responses = error.response;
            console.log(error.response.data);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages.error,
            });
            this.close();
            this.loading = false;
          });
      }
    },

    close() {
      this.$emit("closed", false);
    },
  },
};
</script>
