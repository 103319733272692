<template>
  <div>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Form Pengisian Daftar Hadir</v-toolbar-title>
      </v-toolbar>

      <v-divider></v-divider>

      <v-container fluid>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-select
            v-model="id_kegiatan"
            :items="kegiatanItems"
            :rules="rules"
            item-value="id_kegiatan"
            :item-text="
              (item) =>
                `${item.nama_kegiatan} | Tgl: ${item.tanggal} | Waktu: ${item.jam} | Tempat: ${item.tempat}`
            "
            label="Kegiatan"
            dense
            class="mt-5 text-caption text-md-subtitle-1 text-lg-subtitle-1"
          ></v-select>
          <br />
          <v-select
            v-model="id_kategori"
            :items="kategoriItems"
            :rules="rules"
            item-value="id"
            item-text="kategori"
            label="Kategori Tamu"
            dense
            class="text-caption text-md-subtitle-1 text-lg-subtitle-1"
          ></v-select>
          <br />
          <v-text-field
            v-if="id_kategori == 3"
            v-model="nama_tamu"
            :rules="rules"
            label="Nama"
            dense
            class="text-caption text-md-subtitle-1 text-lg-subtitle-1"
          ></v-text-field>
          <br v-if="id_kategori == 3" />
          <v-text-field
            v-if="id_kategori == 3"
            v-model="jabatan_tamu"
            :rules="rules"
            label="Jabatan"
            dense
            class="text-caption text-md-subtitle-1 text-lg-subtitle-1"
          ></v-text-field>
          <br v-if="id_kategori == 3" />
          <v-text-field
            v-if="id_kategori == 3"
            v-model="instansi_tamu"
            :rules="rules"
            label="Instansi"
            dense
            class="text-caption text-md-subtitle-1 text-lg-subtitle-1"
          ></v-text-field>
          <!-- <v-autocomplete
            v-if="id_kategori == 1 || id_kategori == 2"
            v-model="newsid"
            :items="newsidItems"
            :rules="rules"
            item-value="newsid"
            :item-text="(item) => `${item.nip2} - ${item.nama}`"
            label="Pilih Pegawai"
            v-on:change="pegawaiChange"
            class="text-caption text-md-subtitle-1 text-lg-subtitle-1"
            clearable
          ></v-autocomplete> -->

          <v-autocomplete
            v-if="id_kategori == 1 || id_kategori == 2"
            v-model="newsid"
            :loading="loading"
            :items="newsidItems"
            :search-input.sync="search"
            item-value="newsid"
            item-text="nama"
            label="Masukkan Nama Pegawai"
            v-on:change="pegawaiChange"
            class="text-caption text-md-subtitle-1 text-lg-subtitle-1"
            clearable
            hide-no-data
          ></v-autocomplete>
          <v-simple-table
            v-if="id_kategori == 1 || id_kategori == 2"
            dense
            class="text-caption text-md-subtitle-1 text-lg-subtitle-1"
          >
            <tr valign="top">
              <th colspan="2" align="left"><u>Detail</u></th>
            </tr>
            <tr valign="top">
              <td width="18%">NIP</td>
              <td>: {{ nip_detail }}</td>
            </tr>
            <tr valign="top">
              <td>NAMA</td>
              <td>: {{ nama_detail }}</td>
            </tr>
            <tr valign="top">
              <td>JABATAN</td>
              <td>: {{ jabatan_detail }}</td>
            </tr>
            <tr valign="top">
              <td>INSTANSI</td>
              <td>: {{ instansi_detail }}</td>
            </tr>
          </v-simple-table>

          <br v-if="id_kategori == 1 || id_kategori == 2" />
          Tanda Tangan Dibawah Ini
          <table border="1" style="border-collapse: collapse">
            <tr>
              <td>
                <vueSignature
                  ref="signature"
                  :sigOption="option"
                  :w="'200px'"
                  :h="'200px'"
                ></vueSignature>
              </td>
            </tr>
          </table>

          <v-btn small class="mt-2 mr-2" @click="clear"> Bersihkan </v-btn>
          <v-btn small class="mt-2" @click="undo"> Batal </v-btn>

          <div class="text-xs-center">
            <br />
            <v-btn
              color="primary"
              :disabled="!valid || loading || !izinLokasi"
              @click="submit"
              :loading="loading"
            >
              Simpan

              <v-icon right dark>mdi-content-save</v-icon>
            </v-btn>
            <span v-if="!izinLokasi">
              <br />
              <small class="red--text"
                ><i
                  ><b
                    >*Mohon maaf, anda belum bisa melakukan presensi. Aplikasi
                    tidak dapat membaca lokasi. Silakan ikuti panduan
                    berikut.</b
                  ></i
                ></small
              >
              <br />
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header color="info" class="white--text">
                    Panduan untuk mengaktifkan lokasi
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    1. Pilih icon gembok disudut kiri atas <br />
                    <v-img src="/img/logo/panduan1.jpg"></v-img> <br />
                    2. Pilih Izin <br />
                    <v-img src="/img/logo/panduan2.jpg"></v-img> <br />
                    3. Aktifkan lokasi <br />
                    <v-img src="/img/logo/panduan3.jpg"></v-img> <br />
                    <v-img src="/img/logo/panduan4.jpg"></v-img> <br />
                    4. Refresh kembali aplikasi <br />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </span>
          </div>
        </v-form>
      </v-container>
    </v-card>
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import vueSignature from "vue-signature";
export default {
  name: "Forms",
  components: {
    vueSignature,
  },
  data: () => ({
    option: {
      penColor: "rgb(0, 0, 0)",
      backgroundColor: "rgb(255,255,255)",
    },
    valid: true,
    loading: false,
    id_kegiatan: "",
    id_kategori: "",
    unorid: "",
    newsid: "",
    nama_tamu: "",
    instansi_tamu: "",
    jabatan_tamu: "",
    ttd: "",
    nip_detail: "",
    nama_detail: "",
    instansi_detail: "",
    jabatan_detail: "",
    lng: "",
    lat: "",
    izinLokasi: true,
    kegiatanItems: [],
    kategoriItems: [
      { id: "1", kategori: "Tamu Peserta" },
      { id: "2", kategori: "Tamu Undangan Internal" },
      { id: "3", kategori: "Tamu Undangan Eksternal" },
    ],
    newsidItems: [],
    rules: [(v) => !!v || "Form ini wajib di isi"],
    search: null,
  }),

  created() {
    alert("Aktifkan lokasi pada perangkat saat melakukan presensi.");
    this.initialize();
  },

  watch: {
    search(val) {
      val && val !== this.newsid && this.querySelections(val);
    },
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
    }),

    querySelections(v) {
      let config = {
        headers: {},
      };
      this.axios
        .get("/simpeg/r_dip_all_join/" + v, config)
        .then((response) => {
          this.$vloading.hide();
          // console.log(response.data);
          this.newsidItems = response.data;
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    initialize() {
      this.getKegiatanUUID();
      // this.getPegawai();
      this.$getLocation({
        enableHighAccuracy: true,
      })
        .then((coordinates) => {
          // console.log(coordinates);
          this.lng = coordinates.lng;
          this.lat = coordinates.lat;
          this.izinLokasi = true;
          console.log(this.izinLokasi);
        })
        .catch((error) => {
          console.log(error);
          this.lng = 0;
          this.lat = 0;
          this.izinLokasi = false;
          console.log(this.izinLokasi);
        });
    },

    getKegiatanUUID() {
      this.$vloading.show();
      let config = {
        headers: {},
      };
      this.axios
        .get("/absensi/kegiatanAbsensi/" + this.$route.params.id, config)
        .then((response) => {
          // console.log(response.data);
          // let { data } = response.data;
          this.$vloading.hide();
          this.kegiatanItems = response.data;
          this.id_kegiatan = response.data[0].id_kegiatan;
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    // getPegawai() {
    //   this.$vloading.show();
    //   let config = {
    //     headers: {},
    //   };
    //   this.axios
    //     .get("/simpeg/r_dip_all_join", config)
    //     .then((response) => {
    //       this.$vloading.hide();
    //       // console.log(response.data);
    //       this.newsidItems = response.data;
    //     })
    //     .catch((error) => {
    //       this.$vloading.hide();
    //       console.log(error.response.status);
    //       if (error.response.status == 401) {
    //         this.setAuth({}); // kosongkan auth ketika logout
    //         // this.setAlert({
    //         //   status: true,
    //         //   color: "error",
    //         //   text: "Login First!",
    //         // });
    //         this.$router.replace({ name: "Home" });
    //       } else {
    //         this.setAlert({
    //           status: true,
    //           color: "error",
    //           text: error.response.data.messages,
    //         });
    //         this.close();
    //       }
    //     });
    // },

    pegawaiChange: function(e) {
      // console.log(e);
      this.getPegawaibyId(e);
    },

    getPegawaibyId(id) {
      this.$vloading.show();
      let config = {
        headers: {},
      };
      this.axios
        .get("/simpeg/r_dip_all_join_newsid/" + id, config)
        .then((response) => {
          this.$vloading.hide();
          // console.log(response.data);
          this.nip_detail = response.data[0].nip2;
          this.nama_detail = response.data[0].nama;
          this.instansi_detail = response.data[0].unorname;
          this.jabatan_detail = response.data[0].namajabatan;
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    clear() {
      var _this = this;
      _this.$refs.signature.clear();
    },
    undo() {
      var _this = this;
      _this.$refs.signature.undo();
    },

    submit() {
      if (this.$refs.form.validate()) {
        var _this = this;
        var png = _this.$refs.signature.save();

        this.loading = true;
        // let formData = {
        //   id_kegiatan: this.id_kegiatan,
        //   newsid: this.newsid,
        //   kategori: this.id_kategori,
        //   nama_tamu: this.nama_tamu,
        //   instansi_tamu: this.instansi_tamu,
        //   ttd: png,
        // };
        let formData = new FormData();
        formData.set("id_kegiatan", this.id_kegiatan);
        formData.set("kategori", this.id_kategori);
        if (this.id_kategori == 1 || this.id_kategori == 2) {
          formData.set("newsid", this.newsid);
          formData.set("nama_tamu", "-");
          formData.set("instansi_tamu", "-");
          formData.set("jabatan_tamu", "-");
        } else if (this.id_kategori == 3) {
          formData.set("newsid", "0");
          formData.set("nama_tamu", this.nama_tamu);
          formData.set("instansi_tamu", this.instansi_tamu);
          formData.set("jabatan_tamu", this.jabatan_tamu);
        }
        formData.set("ttd", png);
        formData.set("lng", this.lng);
        formData.set("lat", this.lat);
        this.axios
          .post("/absensi/absensi_add", formData)
          .then(() => {
            this.setAlert({
              status: true,
              color: "success",
              text: "Daftar Hadir Berhasil Ditambahkan!",
            });
            this.close();
            this.loading = false;
            this.$refs.form.reset();
            _this.$refs.signature.clear();
            this.$router.replace({ name: "Home" });
          })
          .catch((error) => {
            // let responses = error.response;
            console.log(error.response.data);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages.error,
            });
            this.close();
            this.loading = false;
          });
      }
    },

    close() {
      this.$emit("closed", false);
    },
  },
};
</script>
