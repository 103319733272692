<template>
  <div>
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text"
          >Masukkan Periode dan Tahun KENPA</v-toolbar-title
        >
      </v-toolbar>
      <v-divider></v-divider>
      <v-container fluid class="pa-10">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row align="center">
            <v-col cols="6">
              <v-select
                v-model="periode"
                :items="periodeItems"
                :rules="rules"
                item-value="id"
                item-text="name"
                label="Periode"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="tahun"
                :rules="rules"
                label="Tahun"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <div class="text-center">
                <v-btn color="secondary" :disabled="!valid" @click="submit">
                  <v-icon left dark>mdi-text-search</v-icon>
                  Lihat
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "MappingData",
  data: () => ({
    valid: true,
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    dialogLoading: false,
    menu2: false,
    dialog: false,
    dialogDelete: false,
    search: "",
    status: "",
    bidang: "all",
    periode: "",
    bulanSampai: new Date().getMonth() + 1,
    tahun: new Date().getFullYear(),
    headers: [
      { text: "No", value: "nomor", width: "5%" },
      { text: "Nama Barang", value: "brand" },
      { text: "Kode Barang", value: "product_code" },
      { text: "Kuantitas", value: "qty" },
      { text: "Bidang", value: "division_name" },
      { text: "Nama Pemesan", value: "full_name" },
      //   { text: "Actions", value: "actions", sortable: false },
    ],
    // exceldepan: "monitoring_",
    // excelblkg: "monitoring_",
    datas: [],
    json_fields: {
      // "No": datas.indexOf(item) + 1,
      // "Nama Barang": "brand",
      "Nama Barang": {
        callback: (value) => {
          return `${value.brand} ${value.type} ${value.product_name}`;
        },
      },
      "Kode Barang": "product_code",
      Kuantitas: "qty",
      Bidang: "division_name",
      "Nama Pemesan": "full_name",
    },
    bidangItems: [],
    periodeItems: [
      { id: "0", name: "April" },
      { id: "1", name: "Oktober" },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    statusItems: [
      { id: 1, name: "Dibuka" },
      { id: 0, name: "Ditutup" },
    ],
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Edit Data";
    },
    passwordConfirmationRule() {
      return () => this.password === this.confpassword || "Password harus sama";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
      setAuth: "auth/set", // <=
    }),

    initialize() {},

    submit() {
      if (this.$refs.form.validate()) {
        localStorage.periode = this.periode;
        localStorage.tahun = this.tahun;
        this.$router.replace({ name: "Registrasi Kenpa" });
      }
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //   DELETE DATA
      //   this.datas.splice(this.editedIndex, 1);
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .delete("/category/" + this.editedItem.id, config)
        .then(() => {
          this.$vloading.hide();
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dihapus!",
          });
          this.close();
          this.getBidang();
          this.closeDelete();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        // EDIT DATA
        // Object.assign(this.datas[this.editedIndex], this.editedItem);
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.access_token,
          },
        };
        let formData = new FormData();
        formData.set("division_name", this.editedItem.division_name);
        formData.set("division_code", this.editedItem.division_code);
        this.axios
          .post("/master/division/" + this.editedItem.id, formData, config)
          .then(() => {
            this.$vloading.hide();
            // console.log(response);
            this.setAlert({
              status: true,
              color: "success",
              text: "Data Berhasil Diubah!",
            });
            this.close();
            this.getBidang();
          })
          .catch((error) => {
            this.$vloading.hide();
            // console.log(error.response.data.messages);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          });
      } else {
        // INSERT DATA
        // this.datas.push(this.editedItem);
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.access_token,
          },
        };
        let formData = new FormData();
        formData.set("division_name", this.editedItem.division_name);
        formData.set("division_code", this.editedItem.division_code);
        this.axios
          .post("/master/division", formData, config)
          .then(() => {
            this.$vloading.hide();
            // console.log(response);
            this.setAlert({
              status: true,
              color: "success",
              text: "Data Berhasil Ditambahkan!",
            });
            this.close();
            this.getBidang();
          })
          .catch((error) => {
            this.$vloading.hide();
            // console.log(error.response.data.messages);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          });
      }
      this.close();
    },
  },
};
</script>