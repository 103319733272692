<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="datas"
      sort-by="calories"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:item.nomor="{ item }">
        {{ datas.indexOf(item) + 1 }}
      </template>
      <template v-slot:item.status="{ item }">
        <span v-if="item.status == 0">
          <v-chip color="red" small text-color="white"> Tutup </v-chip>
        </span>
        <span v-else>
          <v-chip color="green" small text-color="white"> Buka </v-chip>
        </span>
      </template>
      <template v-slot:item.tanggal="{ item }">
        {{ item.tanggal | moment("DD MMMM YYYY") }}
      </template>
      <template v-slot:item.status="{ item }">
        <span v-if="item.status == 0">
          <v-chip color="red" small text-color="white"> Tutup </v-chip>
        </span>
        <span v-else>
          <v-chip color="green" small text-color="white"> Buka </v-chip>
        </span>
      </template>
      <template v-slot:top>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="white--text">Daftar Hadir</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              color="secondary"
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>mdi-plus</v-icon>
              Tambah
            </v-btn>
          </template> -->
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text class="black--text">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.nama_kegiatan"
                        :rules="rules"
                        label="Nama Kegiatan"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        v-model="editedItem.deskripsi"
                        :rules="rules"
                        label="Deskripsi"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.tanggal"
                        :rules="rules"
                        type="date"
                        label="Tgl Kegiatan"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.jam"
                        :rules="rules"
                        label="Jam"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.tempat"
                        :rules="rules"
                        label="Tempat"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        v-model="editedItem.status"
                        :items="statusItems"
                        :rules="rules"
                        dense
                        item-value="id"
                        item-text="status"
                        label="Pilih Status"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close"> Batal </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Simpan </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Anda yakin ingin menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Batal</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-text-field v-model="search" label="Cari" class="mx-4"></v-text-field>
      </template>
      <template v-slot:item.actions="{ item }">
        <!-- <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
        <v-btn
          x-small
          class="mr-1"
          color="secondary"
          @click="lihatAbsensi(item)"
        >
          <v-icon x-small> mdi-table-arrow-right </v-icon>
          &nbsp;Daftar Hadir
        </v-btn>
        <copy-to-clipboard :text="urlAbsen + item.uuid" @copy="handleCopy">
          <v-btn x-small class="mr-1" color="secondary">
            <v-icon x-small> mdi-content-copy </v-icon>
            &nbsp;Copy Link
          </v-btn>
        </copy-to-clipboard>
        <v-btn x-small class="mr-1" color="secondary" @click="qrItem(item)">
          <v-icon x-small> mdi-qrcode </v-icon>
          &nbsp;QR Code
        </v-btn>
      </template>
      <template v-slot:no-data> Tidak ada data </template>
    </v-data-table>
    <v-dialog v-model="dialogQR" max-width="500px">
      <v-card>
        <v-card-title class="text-h5 justify-center">QR CODE</v-card-title>
        <v-card-text>
          <center>
            <h3>{{ editedItem.nama_kegiatan }}</h3>
            <small
              ><b>{{ editedItem.tanggal | moment("DD MMMM YYYY") }}</b></small
            ><br />
            <qr-code
              :text="urlAbsen + editedItem.uuid"
              style="margin-top: 5px"
            ></qr-code>
          </center>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Tutup</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CopyToClipboard from "vue-copy-to-clipboard";
export default {
  components: {
    CopyToClipboard,
  },
  data: () => ({
    urlAbsen: process.env.VUE_APP_FRONTEND_URL + "/forms/",
    text: "",
    dialog: false,
    dialogDelete: false,
    dialogQR: false,
    search: "",
    headers: [
      { text: "No", value: "nomor" },
      {
        text: "Nama Kegiatan",
        align: "left",
        value: "nama_kegiatan",
      },
      { text: "Tgl Kegiatan", value: "tanggal" },
      { text: "Status", value: "status" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    datas: [],
    statusItems: [
      { id: "0", status: "Tutup" },
      { id: "1", status: "Buka" },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Edit Data";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
      setAuth: "auth/set", // <=
    }),

    handleCopy(result) {
      console.log("onCopy", result);
      this.setAlert({
        status: true,
        color: "success",
        text: "Link berhasil di copy!",
      });
      this.close();
    },

    initialize() {
      this.getKegiatan();
    },

    getKegiatan() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/kegiatan/kegiatan_all", config)
        .then((response) => {
          console.log(response.data);
          this.datas = response.data;
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    qrItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogQR = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //   DELETE DATA
      //   this.datas.splice(this.editedIndex, 1);
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .delete(
          "/kegiatan/kegiatan_delete/" + this.editedItem.id_kegiatan,
          config
        )
        .then(() => {
          this.$vloading.hide();
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dihapus!",
          });
          this.close();
          this.getKegiatan();
          this.closeDelete();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.dialogQR = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        // EDIT DATA
        // Object.assign(this.datas[this.editedIndex], this.editedItem);
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.access_token,
          },
        };
        let formData = new FormData();
        formData.set("nama_kegiatan", this.editedItem.nama_kegiatan);
        formData.set("deskripsi", this.editedItem.deskripsi);
        formData.set("tanggal", this.editedItem.tanggal);
        formData.set("jam", this.editedItem.jam);
        formData.set("tempat", this.editedItem.tempat);
        formData.set("status", this.editedItem.status);
        this.axios
          .post(
            "/kegiatan/kegiatan_update/" + this.editedItem.id_kegiatan,
            formData,
            config
          )
          .then(() => {
            this.$vloading.hide();
            // console.log(response);
            this.setAlert({
              status: true,
              color: "success",
              text: "Data Berhasil Diubah!",
            });
            this.close();
            this.getKegiatan();
          })
          .catch((error) => {
            this.$vloading.hide();
            // console.log(error.response.data.messages);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          });
      } else {
        // INSERT DATA
        // this.datas.push(this.editedItem);
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.access_token,
          },
        };
        let formData = new FormData();
        formData.set("nama_kegiatan", this.editedItem.nama_kegiatan);
        formData.set("deskripsi", this.editedItem.deskripsi);
        formData.set("tanggal", this.editedItem.tanggal);
        formData.set("jam", this.editedItem.jam);
        formData.set("tempat", this.editedItem.tempat);
        formData.set("status", this.editedItem.status);
        formData.set("created_by", this.user.data.user_id);
        this.axios
          .post("/kegiatan/kegiatan_add", formData, config)
          .then(() => {
            this.$vloading.hide();
            // console.log(response);
            this.setAlert({
              status: true,
              color: "success",
              text: "Data Berhasil Ditambahkan!",
            });
            this.close();
            this.getKegiatan();
          })
          .catch((error) => {
            this.$vloading.hide();
            // console.log(error.response.data.messages);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          });
      }
      this.close();
    },

    lihatAbsensi(item) {
      localStorage.id_kegiatan = item.id_kegiatan;
      localStorage.nama_kegiatan = item.nama_kegiatan;
      localStorage.uuid = item.uuid;
      this.$router.replace({ name: "Absensi Detail" });
    },
  },
};
</script>