<template>
  <div>
    <v-container class="ma-1 pa-1 pl-2" grid-list-sm
      ><v-row>
        <v-col cols="12" md="8" offset-md="2">
          <div class="text-left">
            <v-btn small to="/statistik" color="secondary">
              <v-icon left>mdi-chevron-left</v-icon> Kembali
            </v-btn>
          </div>
        </v-col></v-row
      >
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-card elevation="5" class="pa-1 mb-2 white--text" color="primary">
            <v-card-title>{{ nama_bidang }}</v-card-title>
            <v-card-subtitle class="text-left white--text"
              >Detail Statistik
            </v-card-subtitle>
          </v-card>
          <v-card elevation="5" class="pa-1 my-4">
            <v-card-title>Jenis Kelamin</v-card-title>
            <v-card-subtitle class="text-left"
              >{{ totalResponden }} jawaban<br />
              <hr
                style="
                  height: 1px;
                  border-width: 0;
                  color: gray;
                  background-color: gray;
                "
              />
            </v-card-subtitle>
            <v-card-text class="text-left text--primary">
              <div id="chart">
                <apexchart
                  type="pie"
                  width="300"
                  :options="chartOptionsJenkel"
                  :series="seriesJenkel"
                ></apexchart>
              </div>
            </v-card-text>
          </v-card>
          <v-card elevation="5" class="pa-1 my-4">
            <v-card-title>Usia</v-card-title>
            <v-card-subtitle class="text-left"
              >{{ totalResponden }} jawaban<br />
              <hr
                style="
                  height: 1px;
                  border-width: 0;
                  color: gray;
                  background-color: gray;
                "
              />
            </v-card-subtitle>
            <v-card-text class="text-left text--primary">
              <v-simple-table height="250px" dense>
                <template v-slot:default>
                  <tbody>
                    <tr v-for="dt in usias" :key="dt.usia">
                      <td>{{ dt.usia }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
          <v-card elevation="5" class="pa-1 my-4">
            <v-card-title>Pendidikan</v-card-title>
            <v-card-subtitle class="text-left"
              >{{ totalResponden }} jawaban<br />
              <hr
                style="
                  height: 1px;
                  border-width: 0;
                  color: gray;
                  background-color: gray;
                "
              />
            </v-card-subtitle>
            <v-card-text class="text-left text--primary">
              <v-simple-table height="250px" dense>
                <template v-slot:default>
                  <tbody>
                    <tr v-for="dt in pendidikans" :key="dt.pendidikan">
                      <td>{{ dt.pendidikan }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
          <v-card elevation="5" class="pa-1 my-4">
            <v-card-title>Pekerjaan</v-card-title>
            <v-card-subtitle class="text-left"
              >{{ totalResponden }} jawaban<br />
              <hr
                style="
                  height: 1px;
                  border-width: 0;
                  color: gray;
                  background-color: gray;
                "
              />
            </v-card-subtitle>
            <v-card-text class="text-left text--primary">
              <v-simple-table height="250px" dense>
                <template v-slot:default>
                  <tbody>
                    <tr v-for="dt in pekerjaans" :key="dt.pekerjaan">
                      <td>{{ dt.pekerjaan }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
          <v-card elevation="5" class="pa-1 my-4">
            <v-card-title>PNS Instansi</v-card-title>
            <v-card-subtitle class="text-left"
              >{{ totalResponden }} jawaban<br />
              <hr
                style="
                  height: 1px;
                  border-width: 0;
                  color: gray;
                  background-color: gray;
                "
              />
            </v-card-subtitle>
            <v-card-text class="text-left text--primary">
              <div id="chart">
                <apexchart
                  type="pie"
                  width="300"
                  :options="chartOptionsPNS"
                  :series="seriesPNS"
                ></apexchart>
              </div>
            </v-card-text>
          </v-card>
          <v-card elevation="5" class="pa-1 my-4">
            <v-card-title>Saran</v-card-title>
            <v-card-subtitle class="text-left"
              >{{ totalResponden }} jawaban<br />
              <hr
                style="
                  height: 1px;
                  border-width: 0;
                  color: gray;
                  background-color: gray;
                "
              />
            </v-card-subtitle>
            <v-card-text class="text-left text--primary">
              <v-simple-table height="250px" dense>
                <template v-slot:default>
                  <tbody>
                    <tr v-for="dt in sarans" :key="dt.saran">
                      <td>{{ dt.saran }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
          <v-card
            elevation="5"
            class="pa-1 my-4"
            v-for="(dt, index) in pertanyaanItems"
            :key="index"
          >
            <v-card-title
              >{{ index + 1 }}. {{ dt.aspek_pertanyaan }}</v-card-title
            >
            <v-card-subtitle class="text-left black--text"
              >{{ totalResponden }} Responden<br />
              <hr
                style="
                  height: 1px;
                  border-width: 0;
                  color: gray;
                  background-color: gray;
                "
              />
            </v-card-subtitle>
            <v-card-text class="text-left text--primary">
              <v-row>
                <v-col cols="12" md="8">
                  <div id="chart">
                    <apexchart
                      type="pie"
                      :options="chartOptions[index]"
                      :series="series[index]"
                    ></apexchart>
                  </div>
                </v-col>
                <v-col cols="12" md="4"><br>
                  <v-chip label color="#4CAF50" class="white--text"
                    ><b> {{ dt.pil4 }} </b></v-chip
                  ><br />
                  <h4>Sejumlah {{ dt.jwbpil4 }} responden</h4>
                  <br />
                  <v-chip label color="#30D5C8" class="white--text"
                    ><b> {{ dt.pil3 }} </b></v-chip
                  ><br />
                  <h4>Sejumlah {{ dt.jwbpil3 }} responden</h4>
                  <br />
                  <v-chip label color="#FEB019" class="white--text"
                    ><b> {{ dt.pil2 }} </b></v-chip
                  ><br />
                  <h4>Sejumlah {{ dt.jwbpil2 }} responden</h4>
                  <br />
                  <v-chip label color="#FF4560" class="white--text"
                    ><b> {{ dt.pil1 }} </b></v-chip
                  ><br />
                  <h4>Sejumlah {{ dt.jwbpil1 }} responden</h4>
                  <br />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    datas: [],
    usias: [],
    pendidikans: [],
    pekerjaans: [],
    sarans: [],
    pertanyaanItems: [],
    id_bidang: localStorage.getItem("id_bidang"),
    nama_bidang: "",
    totalResponden: "",
    seriesJenkel: "",
    chartOptionsJenkel: "",
    seriesPNS: "",
    chartOptionsPNS: "",

    series: [],
    chartOptions: [],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
      setAuth: "auth/set", // <=
    }),

    initialize() {
      this.getStatistikDetail(localStorage.getItem("id_bidang"));
      this.getPertanyaan(localStorage.getItem("id_bidang"));
    },

    getStatistikDetail(id) {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/ikm/statistik/" + id, config)
        .then((response) => {
          // console.log(response.data);
          this.datas = response.data;
          this.usias = response.data[0].usia;
          this.pendidikans = response.data[0].pendidikan;
          this.pekerjaans = response.data[0].pekerjaan;
          this.sarans = response.data[0].saran;
          this.$vloading.hide();

          this.nama_bidang = response.data[0].bidang.nama_bidang;
          this.totalResponden = response.data[0].totalResponden;

          this.seriesJenkel = [
            parseInt(response.data[0].pria),
            parseInt(response.data[0].wanita),
          ];

          this.chartOptionsJenkel = {
            chart: {
              width: 380,
              type: "pie",
            },
            labels: ["Pria", "Wanita"],
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 250,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          };

          this.seriesPNS = [
            parseInt(response.data[0].pnsya),
            parseInt(response.data[0].pnstidak),
          ];

          this.chartOptionsPNS = {
            chart: {
              width: 380,
              type: "pie",
            },
            labels: ["Ya", "Tidak"],
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 250,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          };
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    getPertanyaan(id) {
      this.$vloading.show();
      let config = {
        headers: {
          //   Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/ikm/pertanyaan_join/" + id, config)
        .then((response) => {
          // console.log(response.data);
          this.pertanyaanItems = response.data;
          this.$vloading.hide();

          var data = response.data;

          console.log(data);

          // console.log("length", data[0].pil1);

          for (var i = 0; i < data.length; i++) {
            this.series[i] = [
              parseInt(data[i].jwbpil1),
              parseInt(data[i].jwbpil2),
              parseInt(data[i].jwbpil3),
              parseInt(data[i].jwbpil4),
            ];

            this.chartOptions[i] = {
              chart: {
                type: "pie",
              },
              colors: ["#FF4560", "#FEB019", "#30D5C8", "#4CAF50"],
              labels: [data[i].pil1, data[i].pil2, data[i].pil3, data[i].pil4],
              legend: {
                position: "bottom",
              },
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    legend: {
                      position: "bottom",
                    },
                  },
                },
              ],
            };

            // this.series[i] = [
            //   {
            //     data: [
            //       data[i].jwbpil1,
            //       data[i].jwbpil2,
            //       data[i].jwbpil3,
            //       data[i].jwbpil4,
            //     ],
            //   },
            // ];

            // this.chartOptions[i] = {
            //   chart: {
            //     height: 350,
            //     type: "bar",
            //   },
            //   colors: ["#FF4560", "#FEB019", "#00E396", "#008FFB"],
            //   plotOptions: {
            //     bar: {
            //       columnWidth: "45%",
            //       distributed: true,
            //     },
            //   },
            //   dataLabels: {
            //     enabled: true,
            //   },
            //   legend: {
            //     show: false,
            //   },
            //   xaxis: {
            //     categories: [
            //       data[i].pil1,
            //       data[i].pil2,
            //       data[i].pil3,
            //       data[i].pil4,
            //     ],
            //     labels: {
            //       style: {
            //         fontSize: "12px",
            //       },
            //     },
            //   },
            // };
          }
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },
  },
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>