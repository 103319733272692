<template>
  <div>
    <v-container class="pa-0 mb-3" grid-list-sm>
      <div class="text-left">
        <v-btn small to="/kuesioner" color="secondary">
          <v-icon left>mdi-chevron-left</v-icon> Kembali
        </v-btn>
      </div>
    </v-container>
    <v-container class="pa-0" grid-list-sm>
      <div>
        <v-alert
          color="primary"
          dark
          border="top"
          class="text-center font-weight-bold text-body-2"
        >
          <span v-html="kuesioner"></span>
        </v-alert>
      </div>
    </v-container>
    <v-data-table
      :headers="headers"
      :items="datas"
      sort-by="calories"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:item.nomor="{ item }">
        {{ datas.indexOf(item) + 1 }}
      </template>
      <template v-slot:item.tanggal="{ item }">
        {{ item.tanggal | moment("DD MMM YYYY") }}
      </template>
      <template v-slot:item.status="{ item }">
        <span v-if="item.status == 0">
          <v-chip color="red" small text-color="white"> Tutup </v-chip>
        </span>
        <span v-else>
          <v-chip color="green" small text-color="white"> Buka </v-chip>
        </span>
      </template>
      <template v-slot:top>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="white--text">Pertanyaan</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                color="secondary"
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>mdi-plus</v-icon>
                Tambah
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card-text class="black--text">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.aspek_pertanyaan"
                          :rules="rules"
                          label="Aspek"
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          v-model="editedItem.isi_pertanyaan"
                          :rules="rules"
                          label="Pertanyaan"
                          dense
                          rows="4"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.pil1"
                          :rules="rules"
                          label="Option 1"
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.pil2"
                          :rules="rules"
                          label="Option 2"
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.pil3"
                          :rules="rules"
                          label="Option 3"
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.pil4"
                          :rules="rules"
                          label="Option 4"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Batal
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                    :disabled="!valid"
                  >
                    Simpan
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Anda yakin ingin menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Batal</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-text-field v-model="search" label="Cari" class="mx-4"></v-text-field>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data> Tidak ada data </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    valid: true,
    dialog: false,
    dialogDelete: false,
    search: "",
    menu2: false,
    id_kuesioner: localStorage.getItem("id_kuesioner"),
    kuesioner: localStorage.getItem("kuesioner"),
    headers: [
      { text: "No", value: "nomor" },
      {
        text: "Aspek",
        align: "left",
        value: "aspek_pertanyaan",
      },
      {
        text: "Pertanyaan",
        align: "left",
        value: "isi_pertanyaan",
      },
      { text: "Option 1", value: "pil1" },
      { text: "Option 2", value: "pil2" },
      { text: "Option 3", value: "pil3" },
      { text: "Option 4", value: "pil4" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    datas: [],
    statusItems: [
      { id: "0", status: "Tutup" },
      { id: "1", status: "Buka" },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Edit Data";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
      setAuth: "auth/set", // <=
    }),

    initialize() {
      this.getPertanyaan(this.id_kuesioner);
    },

    getPertanyaan(id) {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/ikm/pertanyaan_by_idkuesioner/" + id, config)
        .then((response) => {
          console.log(response.data);
          this.datas = response.data;
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //   DELETE DATA
      //   this.datas.splice(this.editedIndex, 1);
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .delete(
          "/ikm/pertanyaan_delete/" + this.editedItem.id_pertanyaan,
          config
        )
        .then(() => {
          this.$vloading.hide();
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dihapus!",
          });
          this.close();
          this.getPertanyaan(this.id_kuesioner);
          this.closeDelete();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          // EDIT DATA
          // Object.assign(this.datas[this.editedIndex], this.editedItem);
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.data.access_token,
            },
          };
          let formData = new FormData();
          formData.set("aspek_pertanyaan", this.editedItem.aspek_pertanyaan);
          formData.set("isi_pertanyaan", this.editedItem.isi_pertanyaan);
          formData.set("pil1", this.editedItem.pil1);
          formData.set("pil2", this.editedItem.pil2);
          formData.set("pil3", this.editedItem.pil3);
          formData.set("pil4", this.editedItem.pil4);
          formData.set("id_kuesioner", this.id_kuesioner);
          this.axios
            .post(
              "/ikm/pertanyaan_update/" + this.editedItem.id_pertanyaan,
              formData,
              config
            )
            .then(() => {
              this.$vloading.hide();
              // console.log(response);
              this.setAlert({
                status: true,
                color: "success",
                text: "Data Berhasil Diubah!",
              });
              this.close();
              this.getPertanyaan(this.id_kuesioner);
            })
            .catch((error) => {
              this.$vloading.hide();
              // console.log(error.response.data.messages);
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.messages,
              });
              this.close();
            });
        } else {
          // INSERT DATA
          // this.datas.push(this.editedItem);
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.data.access_token,
            },
          };
          let formData = new FormData();
          formData.set("aspek_pertanyaan", this.editedItem.aspek_pertanyaan);
          formData.set("isi_pertanyaan", this.editedItem.isi_pertanyaan);
          formData.set("pil1", this.editedItem.pil1);
          formData.set("pil2", this.editedItem.pil2);
          formData.set("pil3", this.editedItem.pil3);
          formData.set("pil4", this.editedItem.pil4);
          formData.set("id_kuesioner", this.id_kuesioner);
          this.axios
            .post("/ikm/pertanyaan_add", formData, config)
            .then(() => {
              this.$vloading.hide();
              // console.log(response);
              this.setAlert({
                status: true,
                color: "success",
                text: "Data Berhasil Ditambahkan!",
              });
              this.close();
              this.getPertanyaan(this.id_kuesioner);
            })
            .catch((error) => {
              this.$vloading.hide();
              // console.log(error.response.data.messages);
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.messages,
              });
              this.close();
            });
        }
        this.close();
      }
    },
  },
};
</script>