<template>
  <div>
    <v-container class="ma-1 pa-1 pl-2" grid-list-sm>
      <div class="text-left">
        <v-btn small to="/kuesioner" color="secondary">
          <v-icon left>mdi-chevron-left</v-icon> Kembali </v-btn
        >&nbsp;
        <v-btn small color="info" @click="pdf">
          Export PDF <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-container>
    <v-container class="pa-3 my-n2 mb-n5" grid-list-sm>
      <div>
        <v-alert
          color="primary"
          dark
          border="top"
          class="text-center font-weight-bold text-body-2"
        >
          STATISTIK<br />
          <span v-html="kuesioner"></span>
        </v-alert>
      </div>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" v-for="(dt, index) in datas" :key="index">
          <v-card elevation="5" class="pa-1">
            <v-card-title>{{ dt.nama_bidang }}</v-card-title>
            <v-card-subtitle class="text-left"
              ><small>Statistik</small><br />
              <hr
                style="
                  height: 1px;
                  border-width: 0;
                  color: gray;
                  background-color: gray;
                "
              />
            </v-card-subtitle>
            <v-card-text class="text-left text--primary">
              <v-row>
                <v-col cols="12" md="6">
                  <div id="chart">
                    <apexchart
                      type="bar"
                      height="250"
                      :options="chartOptionsBar"
                      :series="seriesBar[index]"
                    ></apexchart>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <div id="chart">
                    <apexchart
                      type="pie"
                      width="380"
                      :options="chartOptionsPie"
                      :series="seriesPie[index]"
                    ></apexchart>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  class="text-center text-body-1 font-weight-medium"
                >
                  Total Responden : {{ dt.totalResponden }}<br />
                  <div class="text-center pt-2">
                    <v-btn
                      @click="statistikDetail(dt.id_bidang)"
                      color="primary"
                    >
                      Detail<v-icon right>mdi-chevron-right</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    datas: [],
    id_kuesioner: localStorage.getItem("id_kuesioner"),
    kuesioner: localStorage.getItem("kuesioner"),

    seriesBar: [],
    chartOptionsBar: {
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      colors: ["#FF4560", "#FEB019", "#30D5C8", "#4CAF50"],
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: ["Buruk", "Cukup", "Baik", "Sangat Baik"],
        labels: {
          style: {
            fontSize: "12px",
          },
        },
      },
    },
    seriesPie: [],
    chartOptionsPie: [],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
      setAuth: "auth/set", // <=
    }),

    initialize() {
      this.getStatistikAll();
    },

    getStatistikAll() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/ikm/statistik_all", config)
        .then((response) => {
          console.log(response.data);
          this.datas = response.data;
          this.$vloading.hide();

          var data = response.data;

          for (var i = 0; i < data.length; i++) {
            this.seriesBar[i] = [
              {
                data: [
                  data[i].pil1 * 1,
                  data[i].pil2 * 2,
                  data[i].pil3 * 3,
                  data[i].pil4 * 4,
                ],
              },
            ];

            this.seriesPie[i] = [
              parseInt(data[i].pil1 * 1),
              parseInt(data[i].pil2 * 2),
              parseInt(data[i].pil3 * 3),
              parseInt(data[i].pil4 * 4),
            ];
          }

          this.chartOptionsPie = {
            chart: {
              width: 380,
              type: "pie",
            },
            colors: ["#FF4560", "#FEB019", "#30D5C8", "#4CAF50"],
            labels: ["Buruk", "Cukup", "Baik", "Sangat Baik"],
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 250,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          };

          this.chartOptionsPiePrint = {
            chart: {
              width: 380,
              type: "pie",
            },
            colors: ["#FF4560", "#FEB019", "#30D5C8", "#4CAF50"],
            legend: {
              show: false,
            },
            dataLabels: {
              enabled: true,
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 250,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          };
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    statistikDetail(id) {
      localStorage.id_bidang = id;
      // localStorage.nama_kegiatan = item.nama_kegiatan;
      // localStorage.uuid = item.uuid;
      this.$router.replace({ name: "Statistik Detail" });
    },

    pdf() {
      localStorage.id_kuesioner = this.id_kuesioner;
      localStorage.kuesioner = this.kuesioner;
      this.$router.replace({ name: "Statistik PDF" });
    },
  },
};
</script>
