<template>
  <v-data-table
    :headers="headers"
    :items="datas"
    sort-by="calories"
    class="elevation-1"
    :search="search"
  >
    <template v-slot:item.nomor="{ item }">
      {{ datas.indexOf(item) + 1 }}
    </template>
    <template v-slot:top>
      <v-toolbar dark color="primary">
        <v-toolbar-title class="white--text"
          >Setting Maksimal Operator Per SKPD</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-card-text class="black--text">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.unorname"
                        :rules="rules"
                        label="SKPD"
                        dense
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.max_operator"
                        :rules="rules"
                        label="Maksimal Operator"
                        dense
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close"> Batal </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                  :disabled="!valid"
                >
                  Simpan
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Anda yakin ingin menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Batal</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-text-field v-model="search" label="Cari" class="mx-4"></v-text-field>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
    </template>
    <template v-slot:no-data> Tidak ada data </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    valid: true,
    loading: false,
    dialog: false,
    dialogDelete: false,
    search: null,
    cari: null,
    menu2: false,
    headers: [
      { text: "No", value: "nomor" },
      {
        text: "SKPD",
        align: "left",
        value: "unorname",
      },
      {
        text: "Max Operator",
        align: "center",
        value: "max_operator",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    datas: [],
    newsidItems: [],
    statusItems: [
      { id: "0", status: "Tutup" },
      { id: "1", status: "Buka" },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Edit Data";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    cari(val) {
      val && val !== this.newsid && this.querySelections(val);
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
      setAuth: "auth/set", // <=
    }),

    initialize() {
      this.getUnorParent();
    },

    getUnorParent() {
      this.$vloading.show();
      let config = {
        headers: {},
      };
      this.axios
        .get("/simpegoperator/unor_parent", config)
        .then((response) => {
          this.$vloading.hide();
          // console.log(response.data);
          this.datas = response.data;
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //   DELETE DATA
      //   this.datas.splice(this.editedIndex, 1);
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .delete(
          "/PenilaianKompetensi/penilaian_kompetensi_delete/" +
            this.editedItem.id_penilaian,
          config
        )
        .then(() => {
          this.$vloading.hide();
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dihapus!",
          });
          this.close();
          this.getUnorParent();
          this.closeDelete();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          // EDIT DATA
          // Object.assign(this.datas[this.editedIndex], this.editedItem);
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.data.access_token,
            },
          };
          let formData = new FormData();
          formData.set("unorid", this.editedItem.unorid);
          formData.set("max_operator", this.editedItem.max_operator);
          this.axios
            .post(
              "/simpegoperator/simpeg_operator_setting_skpd/" +
                this.editedItem.unorid,
              formData,
              config
            )
            .then(() => {
              this.$vloading.hide();
              // console.log(response);
              this.setAlert({
                status: true,
                color: "success",
                text: "Data Berhasil Diubah!",
              });
              this.close();
              this.getUnorParent();
            })
            .catch((error) => {
              this.$vloading.hide();
              // console.log(error.response.data.messages);
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.messages,
              });
              this.close();
            });
        } else {
          // INSERT DATA
        }

        this.close();
      }
    },
  },
};
</script>