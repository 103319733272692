<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="datas"
      sort-by="calories"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:item.nomor="{ item }">
        {{ datas.indexOf(item) + 1 }}
      </template>
      <template v-slot:item.total="{ item }">
        {{
          parseInt(item.ib) +
          parseInt(item.ic) +
          parseInt(item.id) +
          parseInt(item.iia) +
          parseInt(item.iib) +
          parseInt(item.iic) +
          parseInt(item.iid) +
          parseInt(item.iiia) +
          parseInt(item.iiib) +
          parseInt(item.iiic) +
          parseInt(item.iiid) +
          parseInt(item.iva) +
          parseInt(item.ivb) +
          parseInt(item.ivc) +
          parseInt(item.ivd) +
          parseInt(item.ive) +
          parseInt(item.ix)
        }}
      </template>
      <template v-slot:top>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="white--text"
            >Statistik Golongan Ruang</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                color="secondary"
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>mdi-plus</v-icon>
                Tambah
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card-text class="black--text">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.tahun"
                          :rules="rules"
                          label="Tahun"
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.ib"
                          :rules="rules"
                          label="I/b"
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.ic"
                          :rules="rules"
                          label="I/c"
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.id"
                          :rules="rules"
                          label="I/d"
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.iia"
                          :rules="rules"
                          label="II/a"
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.iib"
                          :rules="rules"
                          label="II/b"
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.iic"
                          :rules="rules"
                          label="II/c"
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.iid"
                          :rules="rules"
                          label="II/d"
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.iiia"
                          :rules="rules"
                          label="III/a"
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.iiib"
                          :rules="rules"
                          label="III/b"
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.iiic"
                          :rules="rules"
                          label="III/c"
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.iiid"
                          :rules="rules"
                          label="III/d"
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.iva"
                          :rules="rules"
                          label="IV/a"
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.ivb"
                          :rules="rules"
                          label="IV/b"
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.ivc"
                          :rules="rules"
                          label="IV/c"
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.ivd"
                          :rules="rules"
                          label="IV/d"
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.ive"
                          :rules="rules"
                          label="IV/e"
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.ix"
                          :rules="rules"
                          label="IX"
                          dense
                          type="number"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Batal
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                    :disabled="!valid"
                  >
                    Simpan
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Anda yakin ingin menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Batal</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-text-field v-model="search" label="Cari" class="mx-4"></v-text-field>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data> Tidak ada data </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    valid: true,
    dialog: false,
    dialogDelete: false,
    search: "",
    menu2: false,
    headers: [
      { text: "No", value: "nomor" },
      {
        text: "Tahun",
        align: "left",
        value: "tahun",
      },
      { text: "I/b", value: "ib" },
      { text: "I/c", value: "ic" },
      { text: "I/d", value: "id" },
      { text: "II/a", value: "iia" },
      { text: "II/b", value: "iib" },
      { text: "II/c", value: "iic" },
      { text: "II/d", value: "iid" },
      { text: "III/a", value: "iiia" },
      { text: "III/b", value: "iiib" },
      { text: "III/c", value: "iiic" },
      { text: "III/d", value: "iiid" },
      { text: "IV/a", value: "iva" },
      { text: "IV/b", value: "ivb" },
      { text: "IV/c", value: "ivc" },
      { text: "IV/d", value: "ivd" },
      { text: "IV/e", value: "ive" },
      { text: "IX", value: "ix" },
      { text: "Total", value: "total" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    datas: [],
    statusItems: [
      { id: "0", status: "Tutup" },
      { id: "1", status: "Buka" },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Edit Data";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
      setAuth: "auth/set", // <=
    }),

    initialize() {
      this.getStatistikGolruang();
    },

    getStatistikGolruang() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/statistik/statistik_golongan_ruang_all", config)
        .then((response) => {
          console.log(response.data);
          this.datas = response.data;
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //   DELETE DATA
      //   this.datas.splice(this.editedIndex, 1);
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .delete(
          "/statistik/statistik_golongan_ruang_delete/" +
            this.editedItem.id_golruang,
          config
        )
        .then(() => {
          this.$vloading.hide();
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dihapus!",
          });
          this.close();
          this.getStatistikGolruang();
          this.closeDelete();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          // EDIT DATA
          // Object.assign(this.datas[this.editedIndex], this.editedItem);
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.data.access_token,
            },
          };
          let formData = new FormData();
          formData.set("tahun", this.editedItem.tahun);
          formData.set("ib", this.editedItem.ib);
          formData.set("ic", this.editedItem.ic);
          formData.set("id", this.editedItem.id);
          formData.set("iia", this.editedItem.iia);
          formData.set("iib", this.editedItem.iib);
          formData.set("iic", this.editedItem.iic);
          formData.set("iid", this.editedItem.iid);
          formData.set("iiia", this.editedItem.iiia);
          formData.set("iiib", this.editedItem.iiib);
          formData.set("iiic", this.editedItem.iiic);
          formData.set("iiid", this.editedItem.iiid);
          formData.set("iva", this.editedItem.iva);
          formData.set("ivb", this.editedItem.ivb);
          formData.set("ivc", this.editedItem.ivc);
          formData.set("ivd", this.editedItem.ivd);
          formData.set("ive", this.editedItem.ive);
          formData.set("ix", this.editedItem.ix);
          this.axios
            .post(
              "/statistik/statistik_golongan_ruang_update/" +
                this.editedItem.id_golruang,
              formData,
              config
            )
            .then(() => {
              this.$vloading.hide();
              // console.log(response);
              this.setAlert({
                status: true,
                color: "success",
                text: "Data Berhasil Diubah!",
              });
              this.close();
              this.getStatistikGolruang();
            })
            .catch((error) => {
              this.$vloading.hide();
              // console.log(error.response.data.messages);
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.messages,
              });
              this.close();
            });
        } else {
          // INSERT DATA
          // this.datas.push(this.editedItem);
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.data.access_token,
            },
          };
          let formData = new FormData();
          formData.set("tahun", this.editedItem.tahun);
          formData.set("ib", this.editedItem.ib);
          formData.set("ic", this.editedItem.ic);
          formData.set("id", this.editedItem.id);
          formData.set("iia", this.editedItem.iia);
          formData.set("iib", this.editedItem.iib);
          formData.set("iic", this.editedItem.iic);
          formData.set("iid", this.editedItem.iid);
          formData.set("iiia", this.editedItem.iiia);
          formData.set("iiib", this.editedItem.iiib);
          formData.set("iiic", this.editedItem.iiic);
          formData.set("iiid", this.editedItem.iiid);
          formData.set("iva", this.editedItem.iva);
          formData.set("ivb", this.editedItem.ivb);
          formData.set("ivc", this.editedItem.ivc);
          formData.set("ivd", this.editedItem.ivd);
          formData.set("ive", this.editedItem.ive);
          formData.set("ix", this.editedItem.ix);
          this.axios
            .post("/statistik/statistik_golongan_ruang_add", formData, config)
            .then(() => {
              this.$vloading.hide();
              // console.log(response);
              this.setAlert({
                status: true,
                color: "success",
                text: "Data Berhasil Ditambahkan!",
              });
              this.close();
              this.getStatistikGolruang();
            })
            .catch((error) => {
              this.$vloading.hide();
              // console.log(error.response.data.messages);
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.messages,
              });
              this.close();
            });
        }
        this.close();
      }
    },
  },
};
</script>