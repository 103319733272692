<template>
  <div>
    <v-container class="ma-1 pa-1" grid-list-sm>
      <div class="text-left">
        <v-btn small to="/absensi" color="secondary">
          <v-icon left>mdi-chevron-left</v-icon> Kembali </v-btn
        >&nbsp;
        <v-btn small color="secondary" @click="initialize()">
          <v-icon left>mdi-refresh</v-icon> Refresh
        </v-btn>
      </div>
    </v-container>
    <v-card flat tile elevation="1" class="mb-2 pt-3">
      <v-simple-table>
        <template v-slot:default>
          <tbody v-for="dt in kegiatanItems" :key="dt.id_kegiatan">
            <tr valign="top">
              <th
                class="
                  text-left text-caption text-md-subtitle-2 text-lg-subtitle-1
                "
              >
                Nama Kegiatan
              </th>
              <th
                class="
                  text-left text-caption text-md-subtitle-2 text-lg-subtitle-1
                "
              >
                : {{ dt.nama_kegiatan }}
              </th>
              <th
                class="
                  text-left text-caption text-md-subtitle-2 text-lg-subtitle-1
                "
              >
                Jam
              </th>
              <th
                class="
                  text-left text-caption text-md-subtitle-2 text-lg-subtitle-1
                "
              >
                : {{ dt.jam }}
              </th>
            </tr>
            <tr valign="top">
              <th
                class="
                  text-left text-caption text-md-subtitle-2 text-lg-subtitle-1
                "
              >
                Tgl Kegiatan
              </th>
              <th
                class="
                  text-left text-caption text-md-subtitle-2 text-lg-subtitle-1
                "
              >
                : {{ dt.tanggal | moment("DD MMMM YYYY") }}
              </th>
              <th
                class="
                  text-left text-caption text-md-subtitle-2 text-lg-subtitle-1
                "
              >
                Tempat
              </th>
              <th
                class="
                  text-left text-caption text-md-subtitle-2 text-lg-subtitle-1
                "
              >
                : {{ dt.tempat }}
              </th>
            </tr>
            <tr valign="top">
              <th
                class="
                  text-left text-caption text-md-subtitle-2 text-lg-subtitle-1
                "
              >
                Deskripsi
              </th>
              <th
                class="
                  text-left text-caption text-md-subtitle-2 text-lg-subtitle-1
                "
              >
                : {{ dt.deskripsi }}
              </th>
              <th
                class="
                  text-left text-caption text-md-subtitle-2 text-lg-subtitle-1
                "
              >
                Status
              </th>
              <th
                class="
                  text-left text-caption text-md-subtitle-2 text-lg-subtitle-1
                "
              >
                : <span v-if="dt.status == 1"> Buka</span
                ><span v-else>Tutup</span>
              </th>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="datas"
      sort-by="calories"
      class="elevation-1"
      dense
    >
      <template v-slot:item.nomor="{ item }">
        {{ datas.indexOf(item) + 1 }}
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | moment("DD-MM-YYYY, HH:mm") }} WITA
      </template>
      <template v-slot:item.lokasi="{ item }">
        <a v-if="item.lng != null" :href="'https://maps.google.com/?q='+item.lat+','+item.lng" target="_blank">Lihat</a>
        <!-- {{ item.lng }} {{ item.lat }} -->
      </template>
      <template v-slot:item.ttd="{ item }">
        <img :src="item.ttd" alt="TTD" style="width: 80px" />
      </template>
      <template v-slot:item.status="{ item }">
        <span v-if="item.status == 0">
          <v-chip color="red" small text-color="white"> Tutup </v-chip>
        </span>
        <span v-else>
          <v-chip color="green" small text-color="white"> Buka </v-chip>
        </span>
      </template>
      <template v-slot:item.pegawai="{ item }">
        <span v-if="item.kategori == '1' || item.kategori == '2'">
          {{ item.pegawai[0].nama }}
        </span>
        <span v-else>
          {{ item.nama_tamu.toUpperCase() }}
        </span>
      </template>
      <template v-slot:item.nip="{ item }">
        <span v-if="item.kategori == '1' || item.kategori == '2'">
          {{ item.pegawai[0].nip2 }}
        </span>
        <span v-else> - </span>
      </template>
      <template v-slot:item.instansi="{ item }">
        <span v-if="item.kategori == '1' || item.kategori == '2'">
          {{ item.skpd[0].unorname }}
        </span>
        <span v-else>
          {{ item.instansi_tamu.toUpperCase() }}
        </span>
      </template>
      <template v-slot:item.jabatan="{ item }">
        <span v-if="item.kategori == '1' || item.kategori == '2'">
          {{ item.jabatan[0].namajabatan.toUpperCase() }}
        </span>
        <span v-else>
          {{ item.jabatan_tamu.toUpperCase() }}
        </span>
      </template>
      <template v-slot:top>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="white--text">Daftar Hadir</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn small color="secondary" class="mb-2" @click="makePDF">
            <v-icon small>mdi-printer</v-icon>
            Export PDF
          </v-btn>
          <v-dialog v-model="dialog" max-width="800px">
            <!-- <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                color="secondary"
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>mdi-plus</v-icon>
                Tambah
              </v-btn>
            </template> -->
            <!-- <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        v-model="id_kegiatan"
                        :items="kegiatanItems"
                        :rules="rules"
                        item-value="id_kegiatan"
                        item-text="nama_kegiatan"
                        label="Kegiatan"
                        disabled
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-autocomplete
                        v-model="unorid"
                        :items="unorItems"
                        :rules="rules"
                        item-value="unorid"
                        item-text="unorname"
                        label="Pilih SKPD"
                        v-on:change="unorChange"
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-autocomplete
                        v-model="newsid"
                        :items="newsidItems"
                        :rules="rules"
                        item-value="newsid"
                        item-text="nama"
                        label="Pilih Pegawai"
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="ttd"
                        :rules="rules"
                        label="Tanda Tangan"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close"> Batal </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Simpan </v-btn>
              </v-card-actions>
            </v-card> -->
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Anda yakin ingin menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Batal</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <!-- <v-text-field v-model="search" label="Cari" class="mx-4"></v-text-field> -->
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data> Tidak ada data </template>
    </v-data-table>

    <div id="print" hidden>
      <center style="font-family: Arial, Helvetica, sans-serif">
        <b>DAFTAR HADIR</b><br /><br />
        <table
          border="0"
          width="100%"
          style="
            border-collapse: collapse;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 12pt;
            margin-top: 2px;
          "
          align="center"
          v-for="dt in kegiatanItems"
          :key="dt.id_kegiatan"
        >
          <tbody>
            <tr>
              <td style="width: 15%">Kegiatan</td>
              <td>: {{ dt.nama_kegiatan }}</td>
            </tr>
            <tr>
              <td>Tanggal</td>
              <td>: {{ dt.tanggal | moment("DD MMMM YYYY") }}</td>
            </tr>
            <tr>
              <td>Tempat</td>
              <td>: {{ dt.tempat }}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <table
          border="1"
          width="100%"
          style="
            border-collapse: collapse;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 10pt;
            margin-top: 2px;
          "
          align="center"
          cellpadding="2px"
        >
          <tbody>
            <tr>
              <th>No.</th>
              <th style="width: 23%">Nama</th>
              <th>NIP</th>
              <th>Jabatan</th>
              <th>Instansi</th>
              <th>Tgl/Waktu</th>
              <th>Tanda Tangan</th>
            </tr>
            <tr v-for="dt in datas" :key="dt.id">
              <td align="center">{{ datas.indexOf(dt) + 1 }}</td>
              <td>
                <span v-if="dt.kategori == '1' || dt.kategori == '2'">
                  {{ dt.pegawai[0].nama }}
                </span>
                <span v-else>
                  {{ dt.nama_tamu.toUpperCase() }}
                </span>
              </td>
              <td>
                <span v-if="dt.kategori == '1' || dt.kategori == '2'">
                  {{ dt.pegawai[0].nip2 }}
                </span>
                <span v-else> - </span>
              </td>
              <td>
                <span v-if="dt.kategori == '1' || dt.kategori == '2'">
                  {{ dt.jabatan[0].namajabatan.toUpperCase() }}
                </span>
                <span v-else>
                  {{ dt.jabatan_tamu.toUpperCase() }}
                </span>
              </td>
              <td>
                <span v-if="dt.kategori == '1' || dt.kategori == '2'">
                  {{ dt.skpd[0].unorname }}
                </span>
                <span v-else>
                  {{ dt.instansi_tamu.toUpperCase() }}
                </span>
              </td>
              <td>{{ dt.created_at | moment("DD-MM-YYYY, HH:mm") }} WITA</td>
              <td align="center">
                <img :src="dt.ttd" alt="TTD" style="width: 50px" />
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td
                colspan="7"
                style="
                  border-right: 1px solid #ffffff;
                  border-left: 1px solid #ffffff;
                  border-bottom: 1px solid #ffffff;
                "
              >
                <small style="color: grey"
                  ><i>*dicetak dari {{ urlweb }}</i></small
                ><qr-code :text="urlAbsen + uuid" :size="50"></qr-code>
              </td>
            </tr>
          </tfoot>
        </table>
      </center>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    urlAbsen: process.env.VUE_APP_FRONTEND_URL + "/forms/",
    urlweb: process.env.VUE_APP_FRONTEND_URL,
    id_kegiatan: localStorage.getItem("id_kegiatan"),
    nama_kegiatan: localStorage.getItem("nama_kegiatan"),
    uuid: localStorage.getItem("uuid"),
    dialog: false,
    dialogDelete: false,
    // search: "",
    unorid: "",
    newsid: "",
    ttd: "",
    headers: [
      { text: "No", value: "nomor" },
      {
        text: "Nama Pegawai",
        align: "left",
        value: "pegawai",
        width: "20%",
      },
      {
        text: "NIP",
        value: "nip",
      },
      {
        text: "Jabatan",
        value: "jabatan",
      },
      {
        text: "Instansi",
        value: "instansi",
      },
      {
        text: "Tgl/Waktu",
        align: "left",
        value: "created_at",
      },
      {
        text: "Tanda Tangan",
        value: "ttd",
      },
      {
        text: "Lokasi",
        value: "lokasi",
      },
    ],
    datas: [],
    kegiatanItems: [],
    unorItems: [],
    newsidItems: [],
    Items: [],
    statusItems: [
      { id: "0", status: "Tutup" },
      { id: "1", status: "Buka" },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Edit Data";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
      setAuth: "auth/set", // <=
    }),

    initialize() {
      this.getAbsensi();
      this.getKegiatanUUID();
      // this.getUnor();
    },

    getAbsensi() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/absensi/absensi_join/" + this.id_kegiatan, config)
        .then((response) => {
          console.log(response.data);
          this.datas = response.data;
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    getKegiatanUUID() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/absensi/kegiatanAbsensi/" + this.uuid, config)
        .then((response) => {
          console.log(response.data);
          this.kegiatanItems = response.data;
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    // getUnor() {
    //   this.$vloading.show();
    //   let config = {
    //     headers: {
    //       Authorization: "Bearer " + this.user.data.access_token,
    //     },
    //   };
    //   this.axios
    //     .get("/simpeg/unor_all", config)
    //     .then((response) => {
    //       // console.log(response.data);
    //       this.unorItems = response.data;
    //       this.$vloading.hide();
    //     })
    //     .catch((error) => {
    //       this.$vloading.hide();
    //       console.log(error.response.status);
    //       if (error.response.status == 401) {
    //         this.setAuth({}); // kosongkan auth ketika logout
    //         // this.setAlert({
    //         //   status: true,
    //         //   color: "error",
    //         //   text: "Login First!",
    //         // });
    //         this.$router.replace({ name: "Home" });
    //       } else {
    //         this.setAlert({
    //           status: true,
    //           color: "error",
    //           text: error.response.data.messages,
    //         });
    //         this.close();
    //       }
    //     });
    // },

    // unorChange: function (e) {
    //   // console.log(e);
    //   this.getPegawai(e);
    // },

    // getPegawai(id) {
    //   this.$vloading.show();
    //   let config = {
    //     headers: {
    //       Authorization: "Bearer " + this.user.data.access_token,
    //     },
    //   };
    //   this.axios
    //     .get("/simpeg/r_dip_unorid/" + id, config)
    //     .then((response) => {
    //       console.log(response.data);
    //       this.newsidItems = response.data;
    //       this.$vloading.hide();
    //     })
    //     .catch((error) => {
    //       this.$vloading.hide();
    //       console.log(error.response.status);
    //       if (error.response.status == 401) {
    //         this.setAuth({}); // kosongkan auth ketika logout
    //         // this.setAlert({
    //         //   status: true,
    //         //   color: "error",
    //         //   text: "Login First!",
    //         // });
    //         this.$router.replace({ name: "Home" });
    //       } else {
    //         this.setAlert({
    //           status: true,
    //           color: "error",
    //           text: error.response.data.messages,
    //         });
    //         this.close();
    //       }
    //     });
    // },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //   DELETE DATA
      //   this.datas.splice(this.editedIndex, 1);
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .delete(
          "/kegiatan/kegiatan_delete/" + this.editedItem.id_kegiatan,
          config
        )
        .then(() => {
          this.$vloading.hide();
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dihapus!",
          });
          this.close();
          this.getAbsensi();
          this.closeDelete();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        // EDIT DATA
        // Object.assign(this.datas[this.editedIndex], this.editedItem);
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.access_token,
          },
        };
        let formData = new FormData();
        formData.set("nama_kegiatan", this.editedItem.nama_kegiatan);
        formData.set("deskripsi", this.editedItem.deskripsi);
        formData.set("tanggal", this.editedItem.tanggal);
        formData.set("jam", this.editedItem.jam);
        formData.set("tempat", this.editedItem.tempat);
        formData.set("status", this.editedItem.status);
        this.axios
          .post(
            "/kegiatan/kegiatan_update/" + this.editedItem.id_kegiatan,
            formData,
            config
          )
          .then(() => {
            this.$vloading.hide();
            // console.log(response);
            this.setAlert({
              status: true,
              color: "success",
              text: "Data Berhasil Diubah!",
            });
            this.close();
            this.getAbsensi();
          })
          .catch((error) => {
            this.$vloading.hide();
            // console.log(error.response.data.messages);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          });
      } else {
        // INSERT DATA
        // this.datas.push(this.editedItem);
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.access_token,
          },
        };
        let formData = new FormData();
        formData.set("id_kegiatan", this.id_kegiatan);
        formData.set("newsid", this.newsid);
        formData.set("ttd", this.ttd);
        this.axios
          .post("/absensi/absensi_add", formData, config)
          .then(() => {
            this.$vloading.hide();
            // console.log(response);
            this.setAlert({
              status: true,
              color: "success",
              text: "Data Berhasil Ditambahkan!",
            });
            this.close();
            this.getAbsensi();
          })
          .catch((error) => {
            this.$vloading.hide();
            // console.log(error.response.data.messages);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages.error,
            });
            this.close();
          });
      }
      this.close();
    },

    makePDF() {
      var prtContent = document.getElementById("print");
      var WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(prtContent.innerHTML);
      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(function() {
        WinPrint.print();
        WinPrint.close();
      }, 500);
    },
  },
};
</script>

<style scoped type="text/css">
thead {
  display: table-header-group;
}
tfoot {
  display: table-footer-group;
}
</style>
