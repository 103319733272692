<template>
  <v-data-table
    :headers="headers"
    :items="datas"
    sort-by="calories"
    class="elevation-1"
    :search="search"
  >
    <template v-slot:item.nomor="{ item }">
      {{ datas.indexOf(item) + 1 }}
    </template>
    <template v-slot:item.jpm="{ item }">
      <span v-if="item.jenjang[0].idjabatan == 63">
        {{ ((item.total / 36) * 100).toFixed(2) }}
      </span>
      <span v-else>
        {{ ((item.total / 27) * 100).toFixed(2) }}
      </span>
    </template>
    <template v-slot:item.jpm_proyeksi="{ item }">
      <span v-if="item.jenjang[0].idjabatan == 63"> - </span>
      <span v-else>
        {{ ((item.total / 36) * 100).toFixed(2) }}
      </span>
    </template>
    <template v-slot:item.kategori_pemetaan="{ item }">
      <span v-if="item.jenjang[0].idjabatan == 63">
        <span v-if="((item.total / 36) * 100).toFixed(2) >= 90"> Optimal </span>
        <span
          v-else-if="
            ((item.total / 36) * 100).toFixed(2) >= 78 &&
            ((item.total / 36) * 100).toFixed(2) < 90
          "
        >
          Cukup Optimal
        </span>
        <span v-else-if="((item.total / 36) * 100).toFixed(2) < 78">
          Kurang Optimal
        </span>
      </span>
      <span v-else>
        <span v-if="((item.total / 27) * 100).toFixed(2) >= 90"> Optimal </span>
        <span
          v-else-if="
            ((item.total / 27) * 100).toFixed(2) >= 78 &&
            ((item.total / 27) * 100).toFixed(2) < 90
          "
        >
          Cukup Optimal
        </span>
        <span v-else-if="((item.total / 27) * 100).toFixed(2) < 78">
          Kurang Optimal
        </span>
      </span>
    </template>
    <template v-slot:item.kategori_pengisian="{ item }">
      <span v-if="item.jenjang[0].idjabatan == 63"> - </span>
      <span v-else>
        <span v-if="((item.total / 36) * 100).toFixed(2) >= 80">
          Memenuhi Syarat
        </span>
        <span
          v-else-if="
            ((item.total / 36) * 100).toFixed(2) >= 68 &&
            ((item.total / 36) * 100).toFixed(2) < 80
          "
        >
          Masih Memenuhi Syarat
        </span>
        <span v-else-if="((item.total / 36) * 100).toFixed(2) < 68">
          Kurang Memenuhi Syarat
        </span>
      </span>
    </template>
    <template v-slot:top>
      <v-toolbar dark color="primary">
        <v-toolbar-title class="white--text"
          >Penilaian Kompetensi</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              color="secondary"
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>mdi-plus</v-icon>
              Tambah
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-card-text class="black--text">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-autocomplete
                        v-model="editedItem.newsid"
                        :loading="loading"
                        :items="newsidItems"
                        :search-input.sync="cari"
                        item-value="newsid"
                        item-text="nama"
                        label="Masukkan Nama Pegawai"
                        clearable
                        hide-no-data
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.ints"
                        :rules="rules"
                        label="Integritas"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.kjs"
                        :rules="rules"
                        label="Kerjasama"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.kom"
                        :rules="rules"
                        label="Komunikasi"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.oph"
                        :rules="rules"
                        label="Orientasi pada Hasil"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.pep"
                        :rules="rules"
                        label="Pelayanan Publik"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.pdo"
                        :rules="rules"
                        label="Pengembangan Diri dan Orang Lain"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.mpu"
                        :rules="rules"
                        label="Mengelola Perubahan"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.pkp"
                        :rules="rules"
                        label="Pengambilan Keputusan"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.prb"
                        :rules="rules"
                        label="Perekat Bangsa"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close"> Batal </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                  :disabled="!valid"
                >
                  Simpan
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Anda yakin ingin menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Batal</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-text-field v-model="search" label="Cari" class="mx-4"></v-text-field>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data> Tidak ada data </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    valid: true,
    loading: false,
    dialog: false,
    dialogDelete: false,
    search: null,
    cari: null,
    menu2: false,
    headers: [
      { text: "No", value: "nomor" },
      {
        text: "Nama",
        align: "left",
        value: "pegawai[0].nama",
      },
      { text: "Jenjang", value: "jenjang[0].nmjabatan" },
      { text: "INT", value: "ints" },
      { text: "KJS", value: "kjs" },
      { text: "KOM", value: "kom" },
      { text: "OPH", value: "oph" },
      { text: "PEP", value: "pep" },
      { text: "PDO", value: "pdo" },
      { text: "MPU", value: "mpu" },
      { text: "PKP", value: "pkp" },
      { text: "PRB", value: "prb" },
      { text: "Total", value: "total" },
      { text: "JPM (%)", value: "jpm" },
      { text: "Kategori Pemetaan", value: "kategori_pemetaan" },
      { text: "JPM Proyeksi (%)", value: "jpm_proyeksi" },
      { text: "Kategori Pengisian Jabatan", value: "kategori_pengisian" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    datas: [],
    newsidItems: [],
    statusItems: [
      { id: "0", status: "Tutup" },
      { id: "1", status: "Buka" },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Edit Data";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    cari(val) {
      val && val !== this.newsid && this.querySelections(val);
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
      setAuth: "auth/set", // <=
    }),

    initialize() {
      this.getPenilaianKompetensi();
    },

    querySelections(v) {
      let config = {
        headers: {},
      };
      this.axios
        .get("/simpeg/r_dip_all_join/" + v, config)
        .then((response) => {
          this.$vloading.hide();
          // console.log(response.data);
          this.newsidItems = response.data;
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    getPenilaianKompetensi() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/PenilaianKompetensi/penilaian_kompetensi_all", config)
        .then((response) => {
          console.log(response.data);
          this.datas = response.data;
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    getPegawai(id) {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/simpeg/r_dip/" + id, config)
        .then((response) => {
          // console.log(response.data);
          this.newsidItems = response.data;
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.getPegawai(item.newsid);
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //   DELETE DATA
      //   this.datas.splice(this.editedIndex, 1);
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .delete(
          "/PenilaianKompetensi/penilaian_kompetensi_delete/" +
            this.editedItem.id_penilaian,
          config
        )
        .then(() => {
          this.$vloading.hide();
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dihapus!",
          });
          this.close();
          this.getPenilaianKompetensi();
          this.closeDelete();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          // EDIT DATA
          // Object.assign(this.datas[this.editedIndex], this.editedItem);
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.data.access_token,
            },
          };
          let formData = new FormData();
          formData.set("newsid", this.editedItem.newsid);
          formData.set("ints", this.editedItem.ints);
          formData.set("kjs", this.editedItem.kjs);
          formData.set("kom", this.editedItem.kom);
          formData.set("oph", this.editedItem.oph);
          formData.set("pep", this.editedItem.pep);
          formData.set("pdo", this.editedItem.pdo);
          formData.set("mpu", this.editedItem.mpu);
          formData.set("pkp", this.editedItem.pkp);
          formData.set("prb", this.editedItem.prb);
          this.axios
            .post(
              "/PenilaianKompetensi/penilaian_kompetensi_update/" +
                this.editedItem.id_penilaian,
              formData,
              config
            )
            .then(() => {
              this.$vloading.hide();
              // console.log(response);
              this.setAlert({
                status: true,
                color: "success",
                text: "Data Berhasil Diubah!",
              });
              this.close();
              this.getPenilaianKompetensi();
            })
            .catch((error) => {
              this.$vloading.hide();
              // console.log(error.response.data.messages);
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.messages,
              });
              this.close();
            });
        } else {
          // INSERT DATA
          // this.datas.push(this.editedItem);
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.data.access_token,
            },
          };
          let formData = new FormData();
          formData.set("newsid", this.editedItem.newsid);
          formData.set("ints", this.editedItem.ints);
          formData.set("kjs", this.editedItem.kjs);
          formData.set("kom", this.editedItem.kom);
          formData.set("oph", this.editedItem.oph);
          formData.set("pep", this.editedItem.pep);
          formData.set("pdo", this.editedItem.pdo);
          formData.set("mpu", this.editedItem.mpu);
          formData.set("pkp", this.editedItem.pkp);
          formData.set("prb", this.editedItem.prb);
          this.axios
            .post(
              "/PenilaianKompetensi/penilaian_kompetensi_add",
              formData,
              config
            )
            .then(() => {
              this.$vloading.hide();
              // console.log(response);
              this.setAlert({
                status: true,
                color: "success",
                text: "Data Berhasil Ditambahkan!",
              });
              this.close();
              this.getPenilaianKompetensi();
            })
            .catch((error) => {
              this.$vloading.hide();
              // console.log(error.response.data.messages);
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.messages,
              });
              this.close();
            });
        }

        this.close();
      }
    },
  },
};
</script>