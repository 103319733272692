<template>
  <div>
    <v-container class="ma-1 pa-1 pl-2" grid-list-sm>
      <div class="text-left">
        <v-btn small to="/statistik" color="secondary">
          <v-icon left>mdi-chevron-left</v-icon> Kembali
        </v-btn>
      </div>
    </v-container>
    <v-container class="pa-3 my-n2" grid-list-sm>
      <v-card class="pa-4">
        <div id="print">
          <table style="width: 100%">
            <tr>
              <th colspan="2" v-html="kuesioner">{{ kuesioner }}</th>
            </tr>
            <tr>
              <td colspan="2">&nbsp;</td>
            </tr>
            <tr v-for="(dt, index) in datas" :key="index">
              <td style="width: 60%">
                <b>{{ dt.nama_bidang }}</b>
                <br />
                Total Responden : {{ dt.totalResponden }}<br />
                <div id="chart">
                  <apexchart
                    type="bar"
                    height="100%"
                    :options="chartOptionsBar"
                    :series="seriesBar[index]"
                  ></apexchart>
                </div>
              </td>
              <td>
                <br />
                <br />
                <div id="chart">
                  <apexchart
                    type="pie"
                    height="100%"
                    :options="chartOptionsPiePrint"
                    :series="seriesPie[index]"
                  ></apexchart>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="text-center pt-8">
          <v-btn color="primary" @click="makePDF">
            <v-icon small>mdi-printer</v-icon>
            Export PDF
          </v-btn>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    datas: [],
    id_kuesioner: localStorage.getItem("id_kuesioner"),
    kuesioner: localStorage.getItem("kuesioner"),

    seriesBar: [],
    chartOptionsBar: {
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      colors: ["#FF4560", "#FEB019", "#30D5C8", "#4CAF50"],
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: ["Buruk", "Cukup", "Baik", "Sangat Baik"],
        labels: {
          style: {
            fontSize: "12px",
          },
        },
      },
    },
    seriesPie: [],
    chartOptionsPie: [],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
      setAuth: "auth/set", // <=
    }),

    initialize() {
      this.getStatistikAll();
    },

    getStatistikAll() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/ikm/statistik_all", config)
        .then((response) => {
          console.log(response.data);
          this.datas = response.data;
          this.$vloading.hide();

          var data = response.data;

          for (var i = 0; i < data.length; i++) {
            this.seriesBar[i] = [
              {
                data: [
                  data[i].pil1 * 1,
                  data[i].pil2 * 2,
                  data[i].pil3 * 3,
                  data[i].pil4 * 4,
                ],
              },
            ];

            this.seriesPie[i] = [
              parseInt(data[i].pil1 * 1),
              parseInt(data[i].pil2 * 2),
              parseInt(data[i].pil3 * 3),
              parseInt(data[i].pil4 * 4),
            ];
          }

          this.chartOptionsPie = {
            chart: {
              width: 380,
              type: "pie",
            },
            colors: ["#FF4560", "#FEB019", "#30D5C8", "#4CAF50"],
            labels: ["Buruk", "Cukup", "Baik", "Sangat Baik"],
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 250,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          };

          this.chartOptionsPiePrint = {
            chart: {
              width: 380,
              type: "pie",
            },
            colors: ["#FF4560", "#FEB019", "#30D5C8", "#4CAF50"],
            legend: {
              show: false,
            },
            dataLabels: {
              enabled: true,
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 250,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          };
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    statistikDetail(id) {
      localStorage.id_bidang = id;
      // localStorage.nama_kegiatan = item.nama_kegiatan;
      // localStorage.uuid = item.uuid;
      this.$router.replace({ name: "Statistik Detail" });
    },

    makePDF() {
      var prtContent = document.getElementById("print");
      var WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(prtContent.innerHTML);
      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(function() {
        WinPrint.print();
        WinPrint.close();
      }, 500);
    },
  },
};
</script>
