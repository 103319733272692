<template>
  <div>
    <v-container class="ma-1 pa-1" grid-list-sm>
      <div class="text-left">
        <v-btn small to="/kenpa" color="secondary">
          <v-icon left>mdi-chevron-left</v-icon> Kembali
        </v-btn>
      </div>
    </v-container>
    <v-data-table
      :headers="headers"
      :items="datas"
      sort-by="calories"
      class="elevation-1 table"
      dense
    >
      <template v-slot:item.nomor="{ item }">
        {{ datas.indexOf(item) + 1 }}
      </template>
      <template v-slot:item.tgl_verifikasi="{ item }">
        {{ item.tgl_verifikasi | moment("DD-MMM-YYYY") }}
      </template>
      <template v-slot:item.nama="{ item }">
        <span
          v-if="
            item.pegawai[0].gelardpn != null && item.pegawai[0].gelardpn != ''
          "
          >{{ item.pegawai[0].gelardpn }}.</span
        >
        {{ item.pegawai[0].nama
        }}<span
          v-if="
            item.pegawai[0].gelarblk != null && item.pegawai[0].gelarblk != ''
          "
          >,
        </span>
        <span
          v-if="
            item.pegawai[0].gelarblk != null && item.pegawai[0].gelarblk != ''
          "
          >{{ item.pegawai[0].gelarblk }}</span
        >
      </template>
      <template v-slot:item.nip="{ item }">
        {{ item.pegawai[0].nip2 }}
      </template>
      <template v-slot:item.instansi="{ item }">
        {{ item.pegawai[0].instansi }}
      </template>
      <template v-slot:item.pangkat_lama="{ item }">
        {{ item.pegawai[0].pangkatgol }}
      </template>
      <template v-slot:item.jenis_kenpa="{ item }">
        {{ item.jenis_kenpa[0].singkatan }}
      </template>
      <template v-slot:item.verifikator="{ item }">
        {{ item.verifikator[0].nama_verifikator }}
      </template>
      <template v-slot:item.status="{ item }">
        <span v-if="item.status == 'TMS'">
          <v-chip color="red" small text-color="white"> TMS </v-chip>
        </span>
        <span v-else-if="item.status == 'PROSES'">
          <v-chip color="orange" small text-color="white"> PROSES </v-chip>
        </span>
        <span v-else>
          <v-chip color="green" small text-color="white"> ACC </v-chip>
        </span>
      </template>
      <template v-slot:item.keterangan="{ item }">
        <span v-if="item.keterangan == 'undefined'"> </span>
        <span v-else>
          {{ item.keterangan }}
        </span>
      </template>
      <template v-slot:top>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="white--text"
            >Registrasi KENPA Periode <span v-if="periode == 0">April</span
            ><span v-else>Oktober</span> Tahun {{ tahun }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn
            small
            color="secondary"
            class="mb-2"
            @click="makePDF"
          >
            <v-icon small>mdi-printer</v-icon>
            Export PDF </v-btn
          >&nbsp;
          <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                color="secondary"
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>mdi-plus</v-icon>
                Tambah
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5"
                  >{{ formTitle }} KENPA Periode
                  <span v-if="periode == 0">April</span
                  ><span v-else>Oktober</span> Tahun {{ tahun }}</span
                >
              </v-card-title>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card-text class="black--text">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-autocomplete
                          :loading="loading"
                          :search-input.sync="cari"
                          clearable
                          hide-no-data
                          v-model="editedItem.newsid"
                          :items="newsidItems"
                          :rules="rules"
                          item-value="newsid"
                          item-text="nama"
                          label="Pilih Pegawai"
                          dense
                          v-on:change="pegawaiChange"
                        ></v-autocomplete>

                        <v-simple-table v-if="editedIndex == -1">
                          <tr>
                            <th colspan="2" align="left"><u>Detail</u></th>
                          </tr>
                          <tr>
                            <td>NIP</td>
                            <td>: {{ nip }}</td>
                          </tr>
                          <tr>
                            <td>NAMA</td>
                            <td>: {{ nama }}</td>
                          </tr>
                          <tr>
                            <td>INSTANSI</td>
                            <td>: {{ instansi }}</td>
                          </tr>
                          <tr>
                            <td>PANGKAT</td>
                            <td>: {{ lama }}</td>
                          </tr>
                        </v-simple-table>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-select
                          v-model="editedItem.pangkat_baru"
                          :items="golruangItems"
                          :rules="rules"
                          dense
                          item-value="idgolruang"
                          item-text="idgolruang"
                          label="Pangkat Baru"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-select
                          v-model="editedItem.id_jenis_kenpa"
                          :items="jeniskenpaItems"
                          :rules="rules"
                          dense
                          item-value="id_jenis"
                          :item-text="
                            (item) => `${item.jenis} - ${item.singkatan}`
                          "
                          label="Jenis KENPA"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.tgl_verifikasi"
                          :rules="rules"
                          type="date"
                          label="Tgl Verifikasi"
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-select
                          v-model="editedItem.id_verifikator"
                          :items="verifikatorItems"
                          :rules="rules"
                          dense
                          item-value="id_verifikator"
                          item-text="nama_verifikator"
                          label="Verifikator"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.nomor_usul"
                          :rules="rules"
                          label="Nomor Usul"
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-select
                          v-model="editedItem.status"
                          :items="statusItems"
                          :rules="rules"
                          dense
                          item-value="id"
                          item-text="status"
                          label="Pilih Status"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          v-model="editedItem.keterangan"
                          label="Keterangan"
                          dense
                          rows="2"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Batal
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                    :disabled="!valid"
                  >
                    Simpan
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Anda yakin ingin menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Batal</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <span class="mx-2"></span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data> Tidak ada data </template>
    </v-data-table>

    <div id="print" hidden>
      <center style="font-family: Arial, Helvetica, sans-serif">
        <b>DAFTAR KENPA</b><br /><br />
        <table
          border="0"
          width="100%"
          style="
            border-collapse: collapse;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 12pt;
            margin-top: 2px;
          "
          align="center"
        >
          <tbody>
            <tr>
              <td style="width: 15%">Periode</td>
              <td>
                : <span v-if="periode == 0">April</span
                ><span v-else>Oktober</span>
              </td>
            </tr>
            <tr>
              <td>Tahun</td>
              <td>: {{ tahun }}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <table
          border="1"
          width="100%"
          style="
            border-collapse: collapse;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 10pt;
            margin-top: 2px;
          "
          align="center"
          cellpadding="3px"
        >
          <tbody>
            <tr>
              <th style="width: 5%">NO</th>
              <th style="width: 25%">NAMA</th>
              <th>NIP</th>
              <th>INSTANSI</th>
              <th>LAMA</th>
              <th>BARU</th>
              <th>JENIS KP</th>
              <th>TGL VERIFIKASI</th>
              <th>VERIFIKATOR</th>
              <th>NOMOR USUL</th>
              <th>ACC/TMS</th>
              <th>KETERANGAN</th>
            </tr>
            <tr v-for="dt in datas" :key="dt.id">
              <td align="center">{{ datas.indexOf(dt) + 1 }}</td>
              <td>
                <span
                  v-if="
                    dt.pegawai[0].gelardpn != null &&
                    dt.pegawai[0].gelardpn != ''
                  "
                  >{{ dt.pegawai[0].gelardpn }}.</span
                >
                {{ dt.pegawai[0].nama
                }}<span
                  v-if="
                    dt.pegawai[0].gelarblk != null &&
                    dt.pegawai[0].gelarblk != ''
                  "
                  >,
                </span>
                <span
                  v-if="
                    dt.pegawai[0].gelarblk != null &&
                    dt.pegawai[0].gelarblk != ''
                  "
                  >{{ dt.pegawai[0].gelarblk }}</span
                >
              </td>
              <td>
                {{ dt.pegawai[0].nip2 }}
              </td>
              <td>
                {{ dt.pegawai[0].instansi }}
              </td>
              <td>
                {{ dt.pegawai[0].pangkatgol }}
              </td>
              <td>
                {{ dt.pangkat_baru }}
              </td>
              <td>
                {{ dt.jenis_kenpa[0].singkatan }}
              </td>
              <td>
                {{ dt.tgl_verifikasi | moment("DD-MMM-YYYY") }}
              </td>
              <td>
                {{ dt.verifikator[0].nama_verifikator }}
              </td>
              <td>
                {{ dt.nomor_usul }}
              </td>
              <td>
                {{ dt.status }}
              </td>
              <td>
                <span v-if="dt.keterangan == 'undefined'"> </span>
                <span v-else>
                  {{ dt.keterangan }}
                </span>
              </td>
            </tr>
          </tbody>
          <!-- <tfoot>
            <tr>
              <td
                colspan="6"
                style="
                  border-right: 1px solid #ffffff;
                  border-left: 1px solid #ffffff;
                  border-bottom: 1px solid #ffffff;
                "
              >
                <small style="color: grey"
                  ><i>*dicetak dari {{ urlweb }}</i></small
                ><qr-code :text="urlAbsen + uuid" :size="50"></qr-code>
              </td>
            </tr>
          </tfoot> -->
        </table>
      </center>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    loading: false,
    cari: null,
    valid: true,
    dialog: false,
    dialogDelete: false,
    nama: "",
    nip: "",
    instansi: "",
    lama: "",
    menu2: false,
    periode: localStorage.getItem("periode"),
    tahun: localStorage.getItem("tahun"),
    headers: [
      { text: "No", value: "nomor" },
      {
        text: "Nama",
        align: "left",
        value: "nama",
      },
      { text: "NIP", value: "nip" },
      { text: "Instansi", value: "instansi" },
      { text: "Lama", value: "pangkat_lama" },
      { text: "Baru", value: "pangkat_baru" },
      { text: "Jenis KP", value: "jenis_kenpa" },
      { text: "Tgl Verifikasi", value: "tgl_verifikasi" },
      { text: "Verifikator", value: "verifikator" },
      { text: "Nomor Usul", value: "nomor_usul" },
      { text: "Status", value: "status" },
      { text: "Ket", value: "keterangan" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    periodeItems: [
      { id: "0", name: "April" },
      { id: "1", name: "Oktober" },
    ],
    datas: [],
    newsidItems: [],
    golruangItems: [],
    jeniskenpaItems: [],
    verifikatorItems: [],
    statusItems: [
      { id: "ACC", status: "ACC" },
      { id: "PROSES", status: "PROSES" },
      { id: "TMS", status: "TMS" },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Edit Data";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    cari(val) {
      val && val !== this.newsid && this.querySelections(val);
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
      setAuth: "auth/set", // <=
    }),

    initialize() {
      this.getKenpa();
      this.getGolRuang();
      this.getJenisKenpa();
      this.getVerifikator();
    },

    querySelections(v) {
      let config = {
        headers: {},
      };
      this.axios
        .get("/simpeg/r_dip_all_join/" + v, config)
        .then((response) => {
          this.$vloading.hide();
          // console.log(response.data);
          this.newsidItems = response.data;
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    getKenpa() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/kenpa/kenpa_filter/" + this.periode + "/" + this.tahun, config)
        .then((response) => {
          console.log(response.data);
          this.datas = response.data;
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    getGolRuang() {
      this.$vloading.show();
      let config = {
        headers: {
          // Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/simpeg/golruang_all", config)
        .then((response) => {
          console.log(response.data);
          this.golruangItems = response.data;
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    getJenisKenpa() {
      this.$vloading.show();
      let config = {
        headers: {
          // Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/kenpa/jenis_kenpa_all", config)
        .then((response) => {
          console.log(response.data);
          this.jeniskenpaItems = response.data;
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    getVerifikator() {
      this.$vloading.show();
      let config = {
        headers: {
          // Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/kenpa/verifikator_all", config)
        .then((response) => {
          console.log(response.data);
          this.verifikatorItems = response.data;
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    pegawaiChange: function (e) {
      // console.log(e);
      this.getDetail(e);
    },

    getDetail(id) {
      this.$vloading.show();
      let config = {
        headers: {
          // Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/simpeg/r_dip_newsid/" + id, config)
        .then((response) => {
          console.log(response.data);
          this.nip = response.data[0].nip2;
          this.nama = response.data[0].nama;
          this.instansi = response.data[0].instansi;
          this.lama = response.data[0].pangkatgol;
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    getPegawai(id) {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/simpeg/r_dip/" + id, config)
        .then((response) => {
          // console.log(response.data);
          this.newsidItems = response.data;
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.getPegawai(item.newsid);
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //   DELETE DATA
      //   this.datas.splice(this.editedIndex, 1);
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .delete("/kenpa/kenpa_delete/" + this.editedItem.id, config)
        .then(() => {
          this.$vloading.hide();
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dihapus!",
          });
          this.close();
          this.getKenpa();
          this.closeDelete();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.nip = "";
      this.nama = "";
      this.instansi = "";
      this.lama = "";
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          // EDIT DATA
          // Object.assign(this.datas[this.editedIndex], this.editedItem);
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.data.access_token,
            },
          };
          let formData = new FormData();
          formData.set("newsid", this.editedItem.newsid);
          formData.set("pangkat_baru", this.editedItem.pangkat_baru);
          formData.set("id_jenis_kenpa", this.editedItem.id_jenis_kenpa);
          formData.set("tgl_verifikasi", this.editedItem.tgl_verifikasi);
          formData.set("id_verifikator", this.editedItem.id_verifikator);
          formData.set("nomor_usul", this.editedItem.nomor_usul);
          formData.set("status", this.editedItem.status);
          formData.set("keterangan", this.editedItem.keterangan);
          formData.set("periode", this.periode);
          formData.set("tahun", this.tahun);
          this.axios
            .post("/kenpa/kenpa_update/" + this.editedItem.id, formData, config)
            .then(() => {
              this.$vloading.hide();
              // console.log(response);
              this.setAlert({
                status: true,
                color: "success",
                text: "Data Berhasil Diubah!",
              });
              this.close();
              this.getKenpa();
            })
            .catch((error) => {
              this.$vloading.hide();
              // console.log(error.response.data.messages);
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.messages,
              });
              this.close();
            });
        } else {
          // INSERT DATA
          // this.datas.push(this.editedItem);
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.data.access_token,
            },
          };
          let formData = new FormData();
          formData.set("newsid", this.editedItem.newsid);
          formData.set("pangkat_baru", this.editedItem.pangkat_baru);
          formData.set("id_jenis_kenpa", this.editedItem.id_jenis_kenpa);
          formData.set("tgl_verifikasi", this.editedItem.tgl_verifikasi);
          formData.set("id_verifikator", this.editedItem.id_verifikator);
          formData.set("nomor_usul", this.editedItem.nomor_usul);
          formData.set("status", this.editedItem.status);
          formData.set("keterangan", this.editedItem.keterangan);
          formData.set("periode", this.periode);
          formData.set("tahun", this.tahun);
          this.axios
            .post("/kenpa/kenpa_add", formData, config)
            .then(() => {
              this.$vloading.hide();
              // console.log(response);
              this.setAlert({
                status: true,
                color: "success",
                text: "Data Berhasil Ditambahkan!",
              });
              this.close();
              this.getKenpa();
            })
            .catch((error) => {
              this.$vloading.hide();
              // console.log(error.response.data.messages);
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.messages,
              });
              this.close();
            });
        }

        this.close();
      }
    },

    makePDF() {
      var prtContent = document.getElementById("print");
      var WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(prtContent.innerHTML);
      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(function () {
        WinPrint.print();
        WinPrint.close();
      }, 500);
    },
  },
};
</script>

<style lang="css" scoped>
.table >>> th {
  font-size: 8pt !important;
}
.table >>> tr > td {
  font-size: 9pt !important;
}

.table >>> .v-data-footer__select,
.table >>> .v-select__selection,
.table >>> .v-data-footer__pagination {
  font-size: 8pt;
}
</style>