<template>
  <v-data-table
    :headers="headers"
    :items="datas"
    sort-by="calories"
    class="elevation-1"
    :search="search"
  >
    <template v-slot:item.nomor="{ item }">
      {{ datas.indexOf(item) + 1 }}
    </template>
    <template v-slot:item.tanggal="{ item }">
      {{ item.tanggal | moment("DD MMM YYYY") }}
    </template>
    <template v-slot:item.kuesioner="{ item }">
      <span v-html="item.kuesioner"></span>
    </template>
    <template v-slot:item.status="{ item }">
      <span v-if="item.status == 0">
        <v-chip color="red" small text-color="white"> Tutup </v-chip>
      </span>
      <span v-else>
        <v-chip color="green" small text-color="white"> Buka </v-chip>
      </span>
    </template>
    <template v-slot:top>
      <v-toolbar dark color="primary">
        <v-toolbar-title class="white--text">Kuesioner</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              color="secondary"
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>mdi-plus</v-icon>
              Tambah
            </v-btn>
          </template> -->
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text class="black--text">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.kuesioner"
                      :rules="rules"
                      label="Nama Kuesioner"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="editedItem.deskripsi"
                      :rules="rules"
                      label="Deskripsi"
                      rows="8"
                      dense
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-select
                      v-model="editedItem.status"
                      :items="statusItems"
                      :rules="rules"
                      dense
                      item-value="id"
                      item-text="status"
                      label="Pilih Status"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Batal </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Simpan </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Anda yakin ingin menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Batal</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-text-field v-model="search" label="Cari" class="mx-4"></v-text-field>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn
        rounded
        class="my-1"
        x-small
        color="secondary"
        @click="statistik(item)"
      >
        <v-icon x-small> mdi-chart-bar </v-icon>
        &nbsp;Statistik </v-btn
      ><br />
      <v-btn
        rounded
        class="my-1"
        x-small
        color="primary"
        @click="pertanyaan(item)"
      >
        <v-icon x-small> mdi-clipboard-list-outline </v-icon>
        &nbsp;Pertanyaan </v-btn
      ><br />
      <v-btn
        rounded
        class="my-1"
        x-small
        color="yellow"
        @click="editItem(item)"
      >
        <v-icon x-small> mdi-pencil </v-icon>
        &nbsp;Edit </v-btn
      ><br />
      <!-- <v-btn
        rounded
        class="my-1"
        x-small
        color="error"
        @click="deleteItem(item)"
      >
        <v-icon x-small> mdi-delete </v-icon>
        &nbsp;Hapus
      </v-btn> -->
      <!-- <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
    </template>
    <template v-slot:no-data> Tidak ada data </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    search: "",
    menu2: false,
    headers: [
      { text: "No", value: "nomor" },
      {
        text: "Nama Kuesioner",
        align: "left",
        value: "kuesioner",
      },
      { text: "Deskripsi", value: "deskripsi", sortable: false, width: "45%" },
      // { text: "Status", value: "status" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    datas: [],
    statusItems: [
      { id: "0", status: "Tutup" },
      { id: "1", status: "Buka" },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Edit Data";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
      setAuth: "auth/set", // <=
    }),

    initialize() {
      this.getKuesioner();
    },

    getKuesioner() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/ikm/kuesioner_all", config)
        .then((response) => {
          console.log(response.data);
          this.datas = response.data;
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //   DELETE DATA
      //   this.datas.splice(this.editedIndex, 1);
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .delete("/ikm/kuesioner_delete/" + this.editedItem.id_kuesioner, config)
        .then(() => {
          this.$vloading.hide();
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dihapus!",
          });
          this.close();
          this.getKuesioner();
          this.closeDelete();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        // EDIT DATA
        // Object.assign(this.datas[this.editedIndex], this.editedItem);
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.access_token,
          },
        };
        let formData = new FormData();
        formData.set("kuesioner", this.editedItem.kuesioner);
        formData.set("deskripsi", this.editedItem.deskripsi);
        formData.set("status", this.editedItem.status);
        this.axios
          .post(
            "/ikm/kuesioner_update/" + this.editedItem.id_kuesioner,
            formData,
            config
          )
          .then(() => {
            this.$vloading.hide();
            // console.log(response);
            this.setAlert({
              status: true,
              color: "success",
              text: "Data Berhasil Diubah!",
            });
            this.close();
            this.getKuesioner();
          })
          .catch((error) => {
            this.$vloading.hide();
            // console.log(error.response.data.messages);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          });
      } else {
        // INSERT DATA
        // this.datas.push(this.editedItem);
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.access_token,
          },
        };
        let formData = new FormData();
        formData.set("kuesioner", this.editedItem.kuesioner);
        formData.set("deskripsi", this.editedItem.deskripsi);
        formData.set("status", this.editedItem.status);
        this.axios
          .post("/ikm/kuesioner_add", formData, config)
          .then(() => {
            this.$vloading.hide();
            // console.log(response);
            this.setAlert({
              status: true,
              color: "success",
              text: "Data Berhasil Ditambahkan!",
            });
            this.close();
            this.getKuesioner();
          })
          .catch((error) => {
            this.$vloading.hide();
            // console.log(error.response.data.messages);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          });
      }
      this.close();
    },

    statistik(item) {
      localStorage.id_kuesioner = item.id_kuesioner;
      localStorage.kuesioner = item.kuesioner;
      this.$router.replace({ name: "Statistik" });
    },

    pertanyaan(item) {
      localStorage.id_kuesioner = item.id_kuesioner;
      localStorage.kuesioner = item.kuesioner;
      this.$router.replace({ name: "Pertanyaan" });
    },
  },
};
</script>